// config/permissions.js
import { useState, useEffect } from 'react';
import { BASE_URL } from './axios';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";

const usePermission = () => {
  const [PERMISSION_ROLE, setPermissionRole] = useState(false);
  const [PERMISSION_AUTHORITY, setPermissionAuthority] = useState(false);
  const [SUB_COMPANIES, setSubCompanies] = useState([]);
  const [role,setRole]=useState(false);
  const [department,setDepartment]=useState(false);

  const updatePermissions = () => {
    const role = sessionStorage.getItem('role');
    const department = sessionStorage.getItem('department');
    setPermissionRole(role === 'HR' || role === 'Admin');
    setPermissionAuthority(role === 'Admin' || role === 'SuperAdmin');
    setRole(role);
    setDepartment(department);
  };
  const getSubCompany = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/subcompanyfilterbysubcompanycount/null/active/`
      );
      setSubCompanies(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    }
  };
  useEffect(() => {
    updatePermissions();
    getSubCompany();
    const handleStorageChange = () => {
      updatePermissions();
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return { PERMISSION_ROLE, PERMISSION_AUTHORITY, updatePermissions, SUB_COMPANIES,role,department };
};

export default usePermission;
