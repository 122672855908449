import React, { useState, useEffect } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BASE_URL_PREBID } from "../../config/axios"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify";
import { formattedDate } from '../Date';
import CreatableSelect from 'react-select/creatable';



const UpdateProject = ({i, id, modalData, allEmployeeData }) => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setFormData(modalData);
    }
    const handleShow = () => {
        getProjectDetails()
        setShow(true);
        setFormData(modalData);
    };
    const [loading, setLoading] = useState(false); //loading logic
    console.log("allEmployeeData")
    console.log(allEmployeeData)
    const department = sessionStorage.getItem('department');



    const [formData, setFormData] = useState({
        project_name: '',
        project_short_name: '',
        client: '',
        concern_person: '',
        tender_id: '',
        project_type: '',
        wo_date: '',
        target_date: '',
        due_date: '',
        cost: '',
        length: '',
        link: '',
        place: '',
        remark: '',
        contract_mode: '',
        location: '',
        otherLocation: '',
        confirm_by: '',
        confirmation_mode: '',
        prebid_prcode: '',
        username: ''

    });

    useEffect(() => {
        setFormData(modalData)
    }, [modalData]);



    const allLocationData = [
        { id: '1', name: 'Andaman and Nicobar Islands' },
        { id: '2', name: 'Andhra Pradesh' },
        { id: '3', name: 'Arunachal Pradesh' },
        { id: '4', name: 'Assam' },
        { id: '5', name: 'Bihar' },
        { id: '6', name: 'Chandigarh' },
        { id: '7', name: 'Chhattisgarh' },
        { id: '8', name: 'Dadra and Nagar Haveli and Daman and Diu' },
        { id: '9', name: 'Delhi' },
        { id: '10', name: 'Goa' },
        { id: '11', name: 'Gujarat' },
        { id: '12', name: 'Haryana' },
        { id: '13', name: 'Himachal Pradesh' },
        { id: '14', name: 'Jammu and Kashmir' },
        { id: '15', name: 'Jharkhand' },
        { id: '16', name: 'Karnataka' },
        { id: '17', name: 'Kerala' },
        { id: '18', name: 'Ladakh' },
        { id: '19', name: 'Lakshadweep' },
        { id: '20', name: 'Madhya Pradesh' },
        { id: '21', name: 'Maharashtra' },
        { id: '22', name: 'Manipur' },
        { id: '23', name: 'Meghalaya' },
        { id: '24', name: 'Mizoram' },
        { id: '25', name: 'Nagaland' },
        { id: '26', name: 'Odisha' },
        { id: '27', name: 'Puducherry' },
        { id: '28', name: 'Punjab' },
        { id: '29', name: 'Rajasthan' },
        { id: '30', name: 'Sikkim' },
        { id: '31', name: 'Tamil Nadu' },
        { id: '32', name: 'Telangana' },
        { id: '33', name: 'Tripura' },
        { id: '34', name: 'Uttar Pradesh' },
        { id: '35', name: 'Uttarakhand' },
        { id: '36', name: 'West Bengal' },
    ];


    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'project_name',
            "project_short_name",
            'client',
            'concern_person',
            'location',
            // 'wo_date',
            'target_date',
            'due_date',
            'contract_mode',
            'confirmation_mode',
            'confirm_by',
            // 'prebid_prcode'

        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    console.log(errors)



    // const isValidDate = (dateString) => {
    //     // Check if the date string is in dd-mm-yyyy format
    //     const datePattern = /^\d{2}-\d{2}-\d{4}$/;

    //     if (!datePattern.test(dateString)) {
    //         return false;
    //     }

    //     const [day, month, year] = dateString.split('-');
    //     const date = new Date(`${year}-${month}-${day}`);
    //     return !isNaN(date.getTime());
    // };



    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {


            if (formData.location === 'Other') {
                setFormData({
                    ...formData,
                    location: formData.otherLocation,
                });


            }
            const UpdatedFormData = {
                ...formData,
                username: sessionStorage.getItem('name')
            }
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL_PREBID}/project/projectupdate/${modalData.pr_code}/`, UpdatedFormData,)

                if (res.status === 200) {
                    window.location.reload();
                    handleClose();
                } else {
                    alert(res);
                }
            } catch (err) {

                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        } else {
            console.log('Form contains errors:', errors);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        // Update the 'location' or 'otherLocation' field based on the 'name' of the input
        if (name === 'location' || name === 'otherLocation') {
            setFormData({
                ...formData,
                [name]: value,
            });
        } else if (name === 'concern_person') {
            // If the input name is 'concern_person', split the value to get both code and name
            const [code, name] = value.split(' - ');

            setFormData({
                ...formData,
                concern_person: code, // Store code in 'concern_person'
                concern_person_name: name, // Store name in 'concern_person_name'
            });
        } else {
            // Handle other fields (e.g., project_name, client, etc.) as usual
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target || e;
        const inputValue = type === 'checkbox' ? checked : value;
        setInputState({
            ...inputState,
            [name]: type === 'checkbox' ? (checked ? 'green' : '') : value.trim() ? 'green' : '',
        });
        setFormData({
            ...formData,
            [name]: inputValue,
        });
    };

    const handleLocationChange = (event) => {
        const { name, value } = event.target;

        // If the selected location is "Other," clear the input field value
        if (value === "Other") {
            setFormData({
                ...formData,
                location: value, // Update the select field value
                otherLocation: "", // Clear the input field value
            });
        } else {
            setFormData({
                ...formData,
                location: value,
            });
        }
    };



    const [allProjectTypes, setAllProjectTypes] = useState([]);
    const getProjectDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/project/unique_project_type/`,
            );
            setAllProjectTypes(Array.from(res.data).filter(type => type !== null).map(type => ({ value: type, label: type })));
        } catch (err) { alert(err.message); }
    }




    return (
        <>

            <button className='upload-svga' onClick={handleShow}>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill='white'
                    id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path><path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path></svg>
            </button>




            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-assign-task dialog-model" >

                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Update   sfhg{formData?.project_short_name} Project </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <div className='modal-body-padding'>
                        <form>
                            <div className='form-flex-wrap'> 

                                <div className="flex-column">
                                    <label className="form-labels">Project Code:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="prebid_prcode"
                                        value={formData.prebid_prcode}
                                        readOnly={formData.prebid_prcode ? true : false}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.prebid_prcode ? 'error' : inputState.prebid_prcode ? 'success' : ''}`}
                                    />
                                    {errors.prebid_prcode && <span className="error-message">{errors.prebid_prcode}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Project Description:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="project_name"
                                        value={formData.project_name}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.project_name ? 'error' : inputState.project_name ? 'success' : ''}`}
                                    />
                                    {errors.name && <span className="error-message">{errors.name}</span>}
                                </div>

                                {department === "Accounts" ?
                                    <>
                                    </>
                                    :
                                    <div className="flex-column">
                                        <label className="form-labels">Project Short Name:<span className="required">*</span></label>
                                        <input
                                            type="text"
                                            maxLength={100}
                                            name="project_short_name"
                                            value={formData.project_short_name}
                                            onChange={handleInputChange}
                                            className={`form-input ${errors.project_short_name ? 'error' : inputState.project_short_name ? 'success' : ''}`}
                                        />
                                        {errors.project_short_name && <span className="error-message">{errors.project_short_name}</span>}
                                    </div>
                                }

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">
                                        Project Type:<span className="required">*</span>
                                    </label>
                                    <CreatableSelect
                                        options={allProjectTypes}
                                        value={formData.project_type ? { value: formData.project_type, label: formData.project_type } : null}
                                        onChange={(option) => handleChange({
                                            target: {
                                                name: 'project_type',
                                                value: option ? option.value : ''
                                            }
                                        })}
                                        placeholder="Select or type a new project type"
                                        // className={`form-input form-group-selection ${errors.project_type ? 'error' : formData.project_type ? 'success' : ''}`}
                                        formatCreateLabel={(inputValue) => `Create new project type: ${inputValue}`}
                                        isValidNewOption={(inputValue, selectValue, selectOptions) =>
                                            inputValue.trim() !== '' &&
                                            !selectOptions.find(option => option.label.toLowerCase() === inputValue.toLowerCase())
                                        }
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '33vw',
                                                height: '50px',
                                                borderRadius: '10px',
                                                border: '1px solid #e3e3e3',
                                                margin: '10px 0px 20px 0px',
                                                color: '#4a4a4a',
                                                backgroundColor: '#f7f8fa',
                                                padding: '0px 10px',
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                borderRadius: '10px',
                                                marginTop: '0',
                                            }),
                                            input: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                        }}
                                    />
                                    {/* <div className={`form-group-selection-arrow ${errors.project_type ? 'error-arrow' : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div> */}
                                    {errors.project_type && (
                                        <span className="error-message">{errors.project_type}</span>
                                    )}
                                </div>

                                {/* <div className="flex-column form-group-select">
                                    <label className="form-labels">Client:<span className="required">*</span></label>
                                    <select
                                        name="client"
                                        value={formData.client}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Client</option>
                                        {
                                            allClientData.length > 0 ?
                                                (allClientData.map((e, index) => (
                                                    <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                                                ))) : (
                                                    <option value="">No Clients Available</option>
                                                )
                                        }

                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.client && (
                                        <span className="error-message">{errors.client}</span>
                                    )}

                                </div> */}
                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Concern Person:<span className="required">*</span></label>
                                    <select
                                        name="concern_person"
                                        value={`${formData.concern_person} - ${formData.concern_person_name}`}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection form-input-background ${errors.concern_person ? 'error' : inputState.concern_person ? 'success' : ''}`}
                                    >
                                        <option value="">Select a concern_person</option>
                                        {allEmployeeData.length > 0 ? (
                                            allEmployeeData.sort((a, b) => a.emp_code - b.emp_code).map((e, index) => (
                                                <option key={index} value={`${e.emp_code} - ${e.name}`}>{e.emp_code} - {e.name}</option>
                                            ))
                                        ) : (
                                            <option value="">No Employee Available</option>
                                        )}
                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.concern_person ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.concern_person && (
                                        <span className="error-message">{errors.concern_person}</span>
                                    )}

                                </div>



                                <div className="flex-column">
                                    <label className="form-labels">Tender Id:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="tender_id"
                                        value={formData.tender_id}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.tender_id ? 'error' : inputState.tender_id ? 'success' : ''}`}
                                    />
                                    {/* {errors.contact_person && <span className="error-message">{errors.contact_person}</span>} */}
                                </div>


                                <div className="flex-column">
                                    <label>Cost ( In Crores):<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="cost"
                                        value={formData.cost}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.cost ? 'error' : inputState.cost ? 'success' : ''}`}
                                    />
                                    {/* {errors.cost && (
                            <span className="error-message">{errors.cost}</span>
                        )} */}
                                </div>


                                <div className="flex-column">
                                    <label className="form-labels">Length (in KMS):<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="length"
                                        value={formData.length}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                                    />
                                    {/* {errors.altcontact_number && (
                            <span className="error-message">{errors.contact_number}</span>
                        )} */}
                                </div>

                                {/* <div className="flex-column">
                                    <label className="form-labels">Workorder Date:<span className="required">*</span></label>
                                    <input
                                        type="date"
                                        name="wo_date"
                                        value={formData.wo_date}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                                    />
                                    {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                                </div> */}


                                <div className="flex-column">
                                    <label className="form-labels">Target Date:<span className="required">*</span></label>
                                    <input
                                        min={formattedDate}
                                        type="date"
                                        name="target_date"
                                        value={formData.target_date}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.target_date ? 'error' : inputState.target_date ? 'success' : ''}`}
                                    />
                                    {errors.target_date && (
                                        <span className="error-message">{errors.target_date}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Due Date:<span className="required">*</span></label>
                                    <input
                                        min={formData.target_date}
                                        type="date"
                                        name="due_date"
                                        value={formData.due_date}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                                    />
                                    {errors.due_date && (
                                        <span className="error-message">{errors.due_date}</span>
                                    )}
                                </div>


                                <div className="flex-column ">
                                    <label className="form-labels">Link:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="link"
                                        value={formData.link}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.link ? 'error' : inputState.link ? 'success' : ''}`}
                                    />
                                    {errors.link && (
                                        <span className="error-message">{errors.link}</span>
                                    )}
                                </div>


                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Location:<span className="required">*</span></label>
                                    {formData.location !== 'Other' ? (
                                        <>
                                            <select
                                                name="location"
                                                value={formData.location}
                                                onChange={handleLocationChange}
                                                className={`form-input form-group-selection ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                            >
                                                <option value="">Select a Location</option>
                                                {
                                                    allLocationData.length > 0 ?
                                                        (allLocationData.map((e, index) => (
                                                            <option key={index} value={e.name}>{e.name}</option>
                                                        ))) : (
                                                            <option value="">No Locations Available</option>
                                                        )
                                                }
                                                <option value="Other">Other</option>
                                            </select><div className={`form-group-selection-arrow ${errors.location ? 'error-arrow' : ''}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div></>) : (
                                        <input
                                            type="text"
                                            name="otherLocation"
                                            value={formData.otherLocation}
                                            onChange={handleInputChange}
                                            placeholder="Enter Other Location"
                                            className={`form-input  ${errors.otherLocation ? 'error' : ''}`}
                                        />
                                    )}
                                    {errors.location && (
                                        <span className="error-message">{errors.location}</span>
                                    )}
                                </div>


                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Contract Mode:<span className="required">*</span></label>
                                    <select
                                        name="contract_mode"
                                        value={formData.contract_mode}
                                        onChange={handleInputChange}
                                        className={`form-input  form-group-selection ${errors.contract_mode ? 'error' : inputState.contract_mode ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Contract Mode</option>
                                        <option value="epc">EPC</option>
                                        <option value="ham">HAM</option>
                                        <option value="bot">BOT</option>



                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.contract_mode ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.contract_mode && (
                                        <span className="error-message ">{errors.contract_mode}</span>
                                    )}

                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Remark:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="remark"
                                        value={formData.remark}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.remark ? 'error' : inputState.remark ? 'success' : ''}`}
                                    />
                                    {/* {errors.contact_person && <span className="error-message">{errors.contact_person}</span>} */}
                                </div>



                                <div className="flex-column">
                                    <label className="form-labels">Confirmed By:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="confirm_by"
                                        value={formData.confirm_by}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                                    />
                                    {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Confirmation Mode:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="confirmation_mode"
                                        value={formData.confirmation_mode}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                                    />
                                    {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                                </div>



                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Status:</label>
                                    <select
                                        name="status"
                                        value={formData.status}
                                        onChange={handleInputChange}
                                        className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Status</option>
                                        <option value="pending">Pending</option>
                                        <option value="inprogress">In Progress</option>
                                        <option value="cancelled">Cancelled</option>
                                        <option value="completed">Completed</option>
                                        <option value="hold">Hold</option>


                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.status && (
                                        <span className="error-message ">{errors.status}</span>
                                    )}

                                </div>
                            </div>

                            <div className="button-models">
                                <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                            </div>
                        </form>
                    </div>

                </Modal.Body>

            </Modal>

        </>
    );
};




const UpdateProjectAmount = ({ id }) => {


    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [formData, setFormData] = useState({
        woamount: '',
        gst_amount: '',
        without_gst_amount: '',
    });

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            "without_gst_amount",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });




        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const [loading, setLoading] = useState(false); //loading logic


    const handleSubmit = async (e) => {
        e.preventDefault();

        const gstPercentage = 0.18; // 18% GST rate in decimal form
        const withoutGstAmount = parseFloat(formData.without_gst_amount);
        // Calculate the GST amount
        const gstAmount = formData.without_gst_amount * gstPercentage;
        const woAmount = withoutGstAmount + gstAmount



        console.log(withoutGstAmount + gstAmount)

        const updatedFormData = {


            ...formData,
            gst_amount: gstAmount,
            woamount: woAmount,
        }

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            try {
                let res = await axios.put(`${BASE_URL_PREBID}/project/projectupdate/${id}/`, updatedFormData,)

                if (res.status === 200) {
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {

                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };







    return (

        <>

            <button className="model-edit-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>


            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-assign-task" >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Update Workorder Amount</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='modal-body-padding'>
                        <form >

                            <div className='form-flex-wrap'>

                                <div className="flex-column">
                                    <label>Work Order Amount Without Gst:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="without_gst_amount"
                                        value={formData.without_gst_amount}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.without_gst_amount ? 'error' : inputState.without_gst_amount ? 'success' : ''}`}
                                    />
                                    {errors.without_gst_amount && <span className="error-message">{errors.without_gst_amount}</span>}
                                </div>
                            </div>

                            <div className="button-models">
                                <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                            </div>
                        </form>
                        <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                    </div>


                </Modal.Body>
            </Modal>

        </>

    );
};



export { UpdateProject, UpdateProjectAmount };