import React from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { routingData } from "./routing";

const RedirectRouter = () => {
  const location = useLocation();
  const role = sessionStorage.getItem("role");
  const department = sessionStorage.getItem("department");

  if (!role || !department) {
    return <Outlet />;
  }

  // Find the roleObject that matches both role and department
  const roleObject = routingData.find((e) => e.role === role && e.department === department);

  if (roleObject) {
    return <Navigate to={roleObject.defaultRoute} state={{ from: location }} replace />;
  } else {
    // Handle the case where there is no matching role and department
    // You can redirect to an error page or handle it as needed
    return <Navigate to="unauthorized" state={{ from: location }} replace />;
  }
}

export default RedirectRouter;
