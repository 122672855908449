import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BASE_URL_PREBID } from "../../config/axios";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const AddStages = ({ id, woamount }) => {

    const [totalPercentage, setTotalPercentage] = useState(0);
    const [inputFields, setInputFields] = useState([{ workStages: '', percentage: '', completed: false, invoice_generated: false }]);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        woamount: '',
        gst_amount: '',
        without_gst_amount: '',
    });
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getClientDetails();
    };

    useEffect(() => {
        getClientDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getClientDetails = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${BASE_URL_PREBID}/project/workstagesbyproject/${id}/`);

            let total = 0;
            for (const stage of res.data) {
                total += stage.percentage || 0;
            }
            setTotalPercentage(total);

        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (index, e) => {
        const { name, value, type, checked } = e.target;
        const updatedFields = [...inputFields];

        if (type === "checkbox") {
            updatedFields[index][name] = checked;
        } else {
            updatedFields[index][name] = value;
        }
        updatedFields[index]["project"] = id;
        setInputFields(updatedFields);
        recalculateTotalPercentage(updatedFields);
    };

    const handleAddInputField = () => {
        setInputFields([...inputFields, { workStages: '', percentage: '', completed: false, invoice_generated: false }]);
    };

    const handleRemoveInputField = (index) => {
        const updatedFields = [...inputFields];
        updatedFields.splice(index, 1);
        setInputFields(updatedFields);
        recalculateTotalPercentage(updatedFields);
    };

    const recalculateTotalPercentage = (fields) => {
        let total = 0;
        fields.forEach((field) => {
            total += Number(field.percentage || 0);
        });
        setTotalPercentage(total);
    };

    const validatePercentage = () => {
        const calculatedTotalPercentage = totalPercentage;
        if (calculatedTotalPercentage > 100) {
            setErrorMessage(`Total percentage should not be greater than 100. Current value is ${calculatedTotalPercentage}`);
            return false;
        } else {
            setErrorMessage("");
            return true;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validatePercentage()) {
            const loadingToastId = toast.loading("Loading: Please wait...");
            setLoading(true);
            try {
                const res = await axios.put(`${BASE_URL_PREBID}/project/stages-add-update-bulk/`, inputFields, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                });
                if (res.status === 200) {
                    // Refresh the data after a successful update
                    getClientDetails();
                    toast.success("Stages updated successfully!");
                } else {
                    toast.error("Error updating stages");
                }
            } catch (err) {
                handleError(err);
            } finally {
                setLoading(false);
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleError = (err) => {
        if (err.response) {
            const errorData = err.response.data;
            if (typeof errorData.error === 'string') {
                toast.error(`Error: ${errorData.error}`);
            } else if (typeof errorData === 'object' && errorData !== null) {
                Object.entries(errorData).forEach(([field, messages]) => {
                    messages.forEach(message => toast.error(`"${field}": ${message}`));
                });
            } else {
                toast.error('Error: Failed to Process!');
            }
        } else {
            toast.error('Error processing your request.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmitWo = async (e) => {
        e.preventDefault();

        if (!formData.without_gst_amount) {
            setErrors({ without_gst_amount: "This field is required." });
            return;
        }




        const gstPercentage = 0.18; // 18% GST rate in decimal form
        const withoutGstAmount = parseFloat(formData.without_gst_amount);
        const gstAmount = (withoutGstAmount * gstPercentage).toFixed(2);
        const woAmount = (withoutGstAmount + parseFloat(gstAmount)).toFixed(2);

        const updatedFormData = {
            ...formData,
            gst_amount: gstAmount,
            woamount: woAmount,
        }


        setLoading(true);
        try {
            const res = await axios.put(`${BASE_URL_PREBID}/project/projectupdate/${id}/`, updatedFormData);

            if (res.status === 200) {
                toast.success("Work Order Amount updated successfully!");
                setFormData({ ...formData, woamount: res.data.woamount });
            } else {
                toast.error("Error updating Work Order Amount");
            }
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <button className='upload-svga' onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill='white' id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path>
                    <path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path>
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-add-client-details">
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Add Stages</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <div>
                        {woamount === null &&
                            <div className='field-cont'>
                                <div className="flex-row">
                                    <label className="form-labels">Work Order Amount Without GST<span className="required">*</span>:</label>
                                    <input
                                        type="text"
                                        name="without_gst_amount"
                                        value={formData.without_gst_amount}
                                        onChange={handleInputChange}
                                        className={`form-input-modal ${errors.without_gst_amount ? 'error' : inputState.without_gst_amount ? 'success' : ''}`}
                                    />
                                    {errors.without_gst_amount && <span className="error-message">{errors.without_gst_amount}</span>}
                                </div>

                                <div className="form-group stages-button">
                                    <div>
                                        <button className="add-stages-button" type="button" onClick={handleSubmitWo}>
                                            Add WorkOrder Amount
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                        <div>
                            <div>
                                {inputFields.map((field, index) => (
                                    <div key={index}>
                                        <h3 className="dashboard-herosection-left-h4">Stage {index + 1}</h3>
                                        <form>
                                            <div className='field-cont'>
                                                <div className="flex-column">
                                                    <label className="form-labels">Stage Name:</label>
                                                    <input
                                                        type="text"
                                                        name="workStages"
                                                        value={field.workStages || ""}
                                                        onChange={(e) => handleChange(index, e)}
                                                        className="form-input-modal form-control-field"
                                                        readOnly={field.invoice_generated}
                                                    />
                                                </div>
                                                <div className="flex-column">
                                                    <label className="form-labels">Stage Percentage:</label>
                                                    <input
                                                        type="number"
                                                        name="percentage"
                                                        value={field.percentage || ""}
                                                        onChange={(e) => handleChange(index, e)}
                                                        className="form-input-modal form-control-field"
                                                        readOnly={field.invoice_generated}
                                                    />
                                                </div>
                                                <div className="flex-row">
                                                    <label className="form-labels">Completed:</label>
                                                    <input
                                                        type="checkbox"
                                                        name="completed"
                                                        checked={field.completed}
                                                        onChange={(e) => handleChange(index, e)}
                                                        className="form-checkbox"
                                                        disabled={field.invoice_generated}
                                                    />
                                                </div>
                                                <div className="flex-row">
                                                    <label className="form-labels">Invoice Generated:</label>
                                                    <input
                                                        type="checkbox"
                                                        name="invoice_generated"
                                                        checked={field.invoice_generated}
                                                        onChange={(e) => handleChange(index, e)}
                                                        className="form-checkbox"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-row stages-button">
                                                <div>
                                                    <button className="add-stages-button" type="button" onClick={handleAddInputField}>
                                                        Add
                                                    </button>
                                                </div>
                                                <div>
                                                    {inputFields.length > 1 && (
                                                        <button type="button" className="remove-stages-button" onClick={() => handleRemoveInputField(index)}>
                                                            Remove
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                ))}
                            </div>

                            {errorMessage && <p className="error-message">{errorMessage}</p>}

                            <button onClick={handleSubmit} disabled={loading}>Submit</button>
                        </div>
                        <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const StagesManagement = ({ id, woamount, getproject }) => {
    const [totalPercentage, setTotalPercentage] = useState(0);
    const [inputFields, setInputFields] = useState([{ workStages: '', percentage: '', completed: false, invoice_generated: false, project: id }]);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        woamount: '',
        gst_amount: '',
        without_gst_amount: '',
    });

    const department = sessionStorage.getItem('department');
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getClientDetails();
    };

    useEffect(() => {
        getClientDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getClientDetails = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${BASE_URL_PREBID}/project/workstagesbyproject/${id}/`);
            setInputFields(res.data.length > 0 ? res.data : [{ workStages: '', percentage: '', completed: false, invoice_generated: false, project: id }]);
            let total = 0;
            res.data.forEach(stage => {
                total += stage.percentage || 0;
            });
            setTotalPercentage(total);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (index, e) => {
        const { name, value, type, checked } = e.target;
        const updatedFields = [...inputFields];
        updatedFields[index][name] = type === "checkbox" ? checked : value;
        setInputFields(updatedFields);
        recalculateTotalPercentage(updatedFields);
    };

    const handleAddInputField = () => {
        setInputFields([...inputFields, { workStages: '', percentage: '', completed: false, invoice_generated: false, project: id }]);
    };

    const handleRemoveInputField = (index) => {
        const updatedFields = [...inputFields];
        updatedFields.splice(index, 1);
        setInputFields(updatedFields);
        recalculateTotalPercentage(updatedFields);
    };

    const recalculateTotalPercentage = (fields) => {
        let total = 0;
        fields.forEach(field => {
            total += Number(field.percentage || 0);
        });
        setTotalPercentage(total);
    };

    const validatePercentage = () => {
        if (totalPercentage > 100) {
            setErrorMessage(`Total percentage should not be greater than 100. Current value is ${totalPercentage}`);
            return false;
        }
        setErrorMessage("");
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validatePercentage()) {
            setLoading(true);
            const loadingToastId = toast.loading("Loading: Please wait...");
            try {
                const res = await axios.put(`${BASE_URL_PREBID}/project/stages-add-update-bulk/`, inputFields, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                });
                if (res.status === 200) {
                    await getClientDetails();
                    await getproject();
                    toast.success("Stages updated successfully!");
                    handleClose();

                } else {
                    toast.error("Error updating stages");
                }
            } catch (err) {
                handleError(err);
            } finally {
                setLoading(false);
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleError = (err) => {
        if (err.response) {
            const errorData = err.response.data;
            if (typeof errorData.error === 'string') {
                toast.error(`Error: ${errorData.error}`);
            } else if (typeof errorData === 'object' && errorData !== null) {
                Object.entries(errorData).forEach(([field, messages]) => {
                    messages.forEach(message => toast.error(`"${field}": ${message}`));
                });
            } else {
                toast.error('Error: Failed to Process!');
            }
        } else {
            toast.error('Error processing your request.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmitWo = async (e) => {
        e.preventDefault();

        if (!formData.without_gst_amount) {
            setErrors({ without_gst_amount: "This field is required." });
            return;
        }

        const gstPercentage = 0.18; // 18% GST rate in decimal form
        const withoutGstAmount = parseFloat(formData.without_gst_amount);
        const gstAmount = (withoutGstAmount * gstPercentage).toFixed(2);
        const woAmount = (withoutGstAmount + parseFloat(gstAmount)).toFixed(2);

        const updatedFormData = {
            ...formData,
            gst_amount: gstAmount,
            woamount: woAmount,
        };

        setLoading(true);
        try {
            const res = await axios.put(`${BASE_URL_PREBID}/project/projectupdate/${id}/`, updatedFormData);

            if (res.status === 200) {
                await getproject();
                toast.success("Work Order Amount updated successfully!");
                setFormData({ ...formData, woamount: res.data.woamount });
                handleClose();
            } else {
                toast.error("Error updating Work Order Amount");
            }
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <button className='upload-svga' onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill='white' id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path>
                    <path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path>
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-add-client-details">
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Manage Stages</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <div>
                        {
                            woamount === null ?
                                <>
                                    <div className='field-cont'>
                                        <div className="flex-row">
                                            <label className="form-labels">Work Order Amount Without GST<span className="required">*</span>:</label>
                                            <input
                                                type="text"
                                                name="without_gst_amount"
                                                value={formData.without_gst_amount}
                                                onChange={handleInputChange}
                                                className={`form-input-modal ${errors.without_gst_amount ? 'error' : inputState.without_gst_amount ? 'success' : ''}`}
                                            />
                                            {errors.without_gst_amount && <span className="error-message">{errors.without_gst_amount}</span>}
                                        </div>

                                        <div className="form-group stages-button">
                                            <div>
                                                <button className="add-stages-button" type="button" onClick={handleSubmitWo}>
                                                    Add WorkOrder Amount
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div>

                                        {inputFields.map((field, index) => (
                                            <div key={index}>
                                                <h3 className="dashboard-herosection-left-h4">Stage {index + 1}</h3>
                                                <form>
                                                    <div className='field-cont'>
                                                        <div className="flex-column">
                                                            <label className="form-labels">Name:</label>
                                                            <input
                                                                type="text"
                                                                name="workStages"
                                                                value={field.workStages || ""}
                                                                onChange={(e) => handleChange(index, e)}
                                                                className="form-input-modal form-control-field"
                                                                readOnly={field.invoice_generated}
                                                            />
                                                        </div>
                                                        <div className="flex-column">
                                                            <label className="form-labels">Percentage:</label>
                                                            <input
                                                                type="number"
                                                                name="percentage"
                                                                value={field.percentage || ""}
                                                                style={{ width: "80px" }}
                                                                onChange={(e) => handleChange(index, e)}
                                                                className="form-input-modal form-control-field"
                                                                readOnly={field.invoice_generated}
                                                            />
                                                        </div>
                                                        <div className="flex-column" style={{ margin: "0px 20px" }}>
                                                            <label className="form-labels">Completed:</label>
                                                            <input
                                                                type="checkbox"
                                                                name="completed"
                                                                checked={field.completed}
                                                                onChange={(e) => handleChange(index, e)}
                                                                className="form-input-modal-checkbox"
                                                                disabled={index !== 0}
                                                            />
                                                        </div>
                                                        <div className="flex-column" >
                                                            <label className="form-labels">Invoice Generated:</label>
                                                            <input
                                                                type="checkbox"
                                                                name="invoice_generated"
                                                                checked={field.invoice_generated}
                                                                onChange={(e) => handleChange(index, e)}
                                                                className="form-input-modal-checkbox"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div>
                                                            {inputFields.length > 1 && !field.invoice_generated && (
                                                                <button type="button" className="remove-stages-button" onClick={() => handleRemoveInputField(index)}>
                                                                    Remove
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="flex-row stages-button">

                                                        <div>


                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        ))}
                                        <div>
                                            <button className="add-stages-button" type="button" onClick={handleAddInputField}>
                                                Add New Stages
                                            </button>
                                        </div>

                                        {errorMessage && <p className="error-message">{errorMessage}</p>}

                                        <button onClick={handleSubmit} className="model-button model-button-leave font-size-heading font-weight500" disabled={loading}>Add & Update Details</button>
                                    </div>
                                </>
                        }



                        <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export { AddStages, StagesManagement };
