import axios from "axios"
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios"
import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import usePermission from "../../config/permissions";
import CreatableSelect from 'react-select/creatable';
import Select from "react-select";

const projectTypeList = [
    { value: 'Residential', label: 'Residential' },
    { value: 'Commercial', label: 'Commercial' },
    { value: 'Infrastructure', label: 'Infrastructure' },
];

const AssignTask = () => {

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-indexed, so add 1
    const day = today.getDate();

    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;


    const [formData, setFormData] = useState({
        task: '',
        project: '',
        concern_person: '',
        priority: '',
        due_date: '',
        assigndate: formattedDate,
        assignedby: '',
        status: "pending",
    });

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            "task",
            "project",
            "concern_person",
            "priority",
            "due_date",

        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });




        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };


    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedFormData = {
            ...formData,
            task: `${formData.project}-${formData.task}`,
            assignedby: sessionStorage.getItem("employee_id"),
            assignedtocc: [sessionStorage.getItem("employee_id")]
        }

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.post(`${BASE_URL}/wfm/tasks/`, updatedFormData, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                })

                if (res.status === 200) {
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                // alert(err);
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };



    // get request Client Data
    useEffect(() => {
        getProjectDetails();
    }, []);

    const role = sessionStorage.getItem('role');
    const department = sessionStorage.getItem('department');

    const [allProjectData, setAllProjectData] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const getProjectDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/project/allproject/`,
            );
            if (role === 'Employee' && department === 'Pre Engineering') {
                const filteredProjects = res.data.filter(project => project.pr_project === false);
                setFilteredProjects(filteredProjects);

            }
            setAllProjectData(res.data);

        } catch (err) {
            alert(err.message);
        }

    }



    useEffect(() => {
        getAllempdata();
    }, []);
    const [allemployeedata, setAllemployee] = useState([]);

    const getAllempdata = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/wfm/ourcompanysdep/${sessionStorage.getItem("departmentid")}/`,);
            setAllemployee(res.data);
        } catch (err) {
            alert(err.message);
        }
    };

    const displayProjects = (role === 'Employee' && department === 'Pre Engineering') ? filteredProjects : allProjectData

    return (
        <div className='modal-body-padding'>
            <form >

                <div className='form-flex-wrap'>


                    <div className="flex-column">
                        <label>Task:</label>
                        <input
                            type="text"
                            name="task"
                            value={formData.task}
                            onChange={handleInputChange}
                            className={`form-input form-control-field-long ${errors.task ? 'error' : inputState.task ? 'success' : ''}`}
                        />
                        {errors.task && (
                            <span className="error-message">{errors.task}</span>
                        )}
                    </div>


                    <div className="flex-column">
                        <label>Due Date:</label>
                        <input
                            type="date"
                            name="due_date"
                            value={formData.due_date}
                            onChange={handleInputChange}
                            className={`form-input ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                        />
                        {errors.due_date && (
                            <span className="error-message">{errors.due_date}</span>
                        )}
                    </div>
                    <div className="flex-column form-group-select">
                        <label>Project Name:</label>
                        <select
                            name="project"
                            value={formData.project}
                            onChange={handleInputChange}
                            className={`form-input form-group-selection ${errors.project ? 'error' : inputState.project ? 'success' : ''}`}
                        >
                            <option value="">Select a Project</option>
                            {
                                displayProjects.length > 0 ?
                                    (displayProjects.map((e, index) => (
                                        <option key={index} value={`${e.prebid_prcode}-${e.project_short_name}`}>{e.prebid_prcode}-{e.project_short_name}</option>
                                    ))) : (
                                        <option value="">No Projects Available</option>
                                    )
                            }
                        </select>
                        <div className="form-group-selection-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                        {errors.project && (
                            <span className="error-message">{errors.project}</span>
                        )}

                    </div>
                    <div className="flex-column form-group-select">
                        <label>Priority:</label>
                        <select
                            name="priority"
                            value={formData.priority}
                            onChange={handleInputChange}
                            className={`form-input form-group-selection ${errors.priority ? 'error' : inputState.priority ? 'success' : ''}`}
                        >
                            <option value="">Select a priority</option>
                            <option value="1">High</option>
                            <option value="2">Medium</option>
                            <option value="3">Low</option>
                        </select>
                        <div className="form-group-selection-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                        {errors.priority && (
                            <span className="error-message">{errors.priority}</span>
                        )}
                    </div>





                    <div className="flex-column form-group-select">
                        <label>Concern Person:</label>
                        <select
                            name="concern_person"
                            value={formData.concern_person}
                            onChange={handleInputChange}
                            className={`form-input form-group-selection ${errors.concern_person ? 'error' : inputState.concern_person ? 'success' : ''}`}
                        >
                            <option value="">Select a Employee</option>
                            {
                                allemployeedata.length > 0 ?
                                    (allemployeedata.map((e, index) => (
                                        <option key={index} value={e.emp_code}>{e.emp_code} - {e.name}</option>
                                    ))) : (
                                        <option value="">No Employee Available</option>
                                    )
                            }
                        </select>
                        <div className="form-group-selection-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                        {errors.concern_person && (
                            <span className="error-message">{errors.concern_person}</span>
                        )}

                    </div>
                </div>

                <div className="button-models">
                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                </div>
            </form>
        </div>
    );
};


const AddProjectDetails = () => {
    const [formData, setFormData] = useState({
        project_name: '',
        project_short_name: '',
        prebid_prcode: '',
        client: '',
        pr_clients: [],
        tender_id: '',
        project_type: '',
        wo_date: '',
        cost: '',
        length: '',
        remark: '',
        confirm_by: '',
        confirmation_mode: '',
        pr_project: false,

    });
    const [isSubmitted, setIsSubmitted] = useState(false);


    const { role, department } = usePermission();
    const [show, setShow] = useState(false);

    const handleShow = () => {

        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        setFormData({
            project_name: '',
            project_short_name: '',
            prebid_prcode: '',
            client: '',
            tender_id: '',
            project_type: '',
            wo_date: '',
            cost: '',
            length: '',
            remark: '',
            confirm_by: '',
            confirmation_mode: '',
            pr_project: false,

        })
        window.location.reload();
        setErrors({});
    }




    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        // Initial required fields
        let requiredFields = [
            'project_name',
            'project_short_name',
            'prebid_prcode',
            'client',
            'project_type',
            // 'tender_id',
            'wo_date',
            'cost',
            'length',
            'remark',
            'confirm_by',
            'confirmation_mode',
        ];

        // Remove 'project_short_name' if department is 'Accounts'
        if (department === 'Accounts') {
            let requiredFields = [
                // 'project_name',
                // 'project_short_name',
                // 'prebid_prcode',
                // 'client',
                // 'project_type',
                // // 'tender_id',
                // 'wo_date',
                // 'cost',
                // 'length',
                // 'remark',
                // 'confirm_by',
                // 'confirmation_mode',
            ];

        }


        // Validate required fields


        setErrors(newErrors);
        setIsSubmitted(true);

        return Object.keys(newErrors).length === 0;
    };


    const [loading, setLoading] = useState(false); //loading logic

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setInputState({
            ...inputState,
            [name]: type === 'checkbox' ? (checked ? 'green' : '') : value.trim() ? 'green' : '',
        });


        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });


        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target || e;

        // Handle checkbox, CreatableSelect, or standard input change
        const inputValue = type === 'checkbox' ? checked : value;

        setInputState({
            ...inputState,
            [name]: type === 'checkbox' ? (checked ? 'green' : '') : value.trim() ? 'green' : '',
        });

        setFormData({
            ...formData,
            [name]: inputValue,
        });
    };






    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.post(`${BASE_URL_PREBID}/project/addproject/`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                })

                if (res.status === 200) {
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Project added successfully!");
                } else {
                    alert(res);
                }
            } catch (err) {
                // alert(err);
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const [allClientData, setAllClientData] = useState([]);
    const getClientDetails = async () => {
        try {
            const res = await axios.get(`${BASE_URL_PREBID}/project/allclient/`);

            setAllClientData(res.data || []); // Ensure it's an array
        } catch (err) {
            console.error("Error fetching client data:", err.message);
            alert(err.message);
        }
    };





    const [prClient, setPrClient] = useState("null");

    // get request Client Data
    useEffect(() => {
        getProjectDetails();
        getClientDetails();
    }, []);


    const option =
        allClientData
            .sort((a, b) => parseInt(a.name) - parseInt(b.name))
            .map((e) => ({
                value: e.id,
                label: `${e.name} - ${e.pos}`,
            }))




    const handleChangecc = (selected) => {
        const pr_clients =
            selected.length > 0 ? selected.map((item) => item.value) : "";
        setFormData((prevState) => ({
            ...prevState,
            pr_clients,
        }));



    };
    const [allProjectTypes, setAllProjectTypes] = useState([]);
    const getProjectDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/project/unique_project_type/`,
            );
            // setAllProjectTypes(res.data);
            setAllProjectTypes(Array.from(res.data).filter(type => type !== null).map(type => ({ value: type, label: type })));

        } catch (err) {
            alert(err.message);
        }

    }

    return (
        <div className='modal-body-padding'>
            <form>
                <div className='form-flex-wrap'>



                    <div className="flex-column">
                        <div className="flex-column">
                            <label className="form-labels">Project Code:
                                {/* <span className="required">*</span> */}
                            </label>
                            <input
                                type="text"
                                name="prebid_prcode"
                                value={formData.prebid_prcode}
                                onChange={handleInputChange}
                                className={`form-input  ${errors.prebid_prcode ? 'error' : inputState.prebid_prcode ? 'success' : ''}`}
                            />
                            {errors.prebid_prcode && <span className="error-message">{errors.prebid_prcode}</span>}
                        </div>
                        {/* <div className="flex-row">
                            <label className="form-labels">PR Project:</label>

                            <input
                                name="pr_project"
                                type="checkbox"
                                value={formData.pr_project}
                                className={`form-checkbox ${errors.pr_project ? 'error' : inputState.pr_project ? 'success' : ''}`}
                                onChange={handleInputChange}
                                checked={formData.pr_project}
                            />
                            {errors.pr_project && <span className="error-message">{errors.pr_project}</span>}

                        </div> */}
                    </div>



                    <div className="flex-column ">
                        <label className="form-labels">Project Description:
                            {/* <span className="required">*</span> */}
                        </label>
                        <textarea
                            type="text"
                            name="project_name"
                            maxLength={300}
                            value={formData.project_name}
                            onChange={handleInputChange}
                            className={`form-input-textarea  ${errors.project_name ? 'error' : inputState.project_name ? 'success' : ''}`}
                        />
                        {errors.project_name && <span className="error-message">{errors.project_name}</span>}
                    </div>
                    {department === "Accounts" ?
                        <>
                        </>
                        :
                        <div className="flex-column">
                            <label className="form-labels">Project Short Name:
                                {/* <span className="required">*</span> */}
                            </label>
                            <input
                                type="text"
                                maxLength={100}
                                name="project_short_name"
                                value={formData.project_short_name}
                                onChange={handleInputChange}
                                className={`form-input ${errors.project_short_name ? 'error' : inputState.project_short_name ? 'success' : ''}`}
                            />
                            {errors.project_short_name && <span className="error-message">{errors.project_short_name}</span>}
                        </div>
                    }


                    <div className="flex-column form-group-select">
                        <label className="form-labels">Client:
                            {/* <span className="required">*</span> */}
                        </label>
                        <select
                            name="client"
                            value={formData.client}
                            onChange={handleInputChange}
                            className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                        >
                            <option value="">Select a Client</option>
                            {
                                allClientData.length > 0 ?
                                    (allClientData.sort((a, b) =>
                                        a.name.localeCompare(b.name, "en", {
                                            ignorePunctuation: true,
                                        })
                                    ).map((e, index) => (
                                        <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                                    ))) : (
                                        <option value="">No Clients Available</option>
                                    )
                            }

                        </select>
                        <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                        {errors.client && (
                            <span className="error-message">{errors.client}</span>
                        )}

                    </div>
                    {department==="Accounts"?
                    <>
                    <div className="flex-column">
                        <label htmlFor="pr_clients" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                            PR Clients
                            {/* <span className="required">*</span> */}
                        </label>

                        <Select options={option} isMulti onChange={handleChangecc}
                            styles={{

                                control: (provided) => ({
                                    ...provided,
                                    width: '33vw',
                                    minHeight: '50px',
                                    borderRadius: '10px',
                                    border: '1px solid #e3e3e3',
                                    margin: '10px 0px 20px 0px',
                                    color: '#4a4a4a',
                                    backgroundColor: '#f7f8fa',
                                    padding: '0px 10px',
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: '#4a4a4a',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    width: '33vw',
                                    borderRadius: '10px',
                                    marginTop: '0',
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: '#4a4a4a',
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    color: '#4a4a4a',
                                }),
                            }} /><br />
                        {errors.pr_clients && (
                            <span className="error-message font-size-text ">{errors.pr_clients}</span>
                        )}
                    </div>
                    </>:
                    <>
                    </>
                    }
                    
                    <div className="flex-column form-group-select">
                        <label className="form-labels">
                            Project Type:
                            {/* <span className="required">*</span> */}
                        </label>
                        <CreatableSelect
                            options={allProjectTypes}
                            value={formData.project_type ? { value: formData.project_type, label: formData.project_type } : null}
                            onChange={(option) => handleChange({
                                target: {
                                    name: 'project_type',
                                    value: option ? option.value : ''
                                }
                            })}
                            placeholder="Select or type a new project type"
                            formatCreateLabel={(inputValue) => `Create new project type: ${inputValue}`}
                            isValidNewOption={(inputValue, selectValue, selectOptions) =>
                                inputValue.trim() !== '' &&
                                !selectOptions.find(option => option.label.toLowerCase() === inputValue.toLowerCase())
                            }
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '33vw',
                                    height: '50px',
                                    borderRadius: '10px',
                                    border: '1px solid #e3e3e3',
                                    margin: '10px 0px 20px 0px',
                                    color: '#4a4a4a',
                                    backgroundColor: '#f7f8fa',
                                    padding: '0px 10px',
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: '#4a4a4a',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    borderRadius: '10px',
                                    marginTop: '0',
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    color: '#4a4a4a',
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: '#4a4a4a',
                                }),
                            }}
                        />
                        {/* <div className={`form-group-selection-arrow ${errors.project_type ? 'error-arrow' : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div> */}
                        {errors.project_type && (
                            <span className="error-message">{errors.project_type}</span>
                        )}
                    </div>

                    <div className="flex-column">
                        <label className="form-labels">Tender Id:</label>

                        <input
                            type="text"
                            name="tender_id"
                            value={formData.tender_id}
                            onChange={handleInputChange}
                            className={`form-input ${errors.tender_id ? 'error' : inputState.tender_id ? 'success' : ''}`}
                        />
                        {errors.tender_id && <span className="error-message">{errors.tender_id}</span>}
                    </div>


                    <div className="flex-column">
                        <label>Cost ( In Crores):
                            {/* <span className="required">*</span> */}
                        </label>
                        <input
                            type="number"
                            step="0.01"
                            name="cost"
                            value={formData.cost}
                            onChange={handleInputChange}
                            className={`form-input ${errors.cost ? 'error' : inputState.cost ? 'success' : ''}`}
                        />
                        {errors.cost && (
                            <span className="error-message">{errors.cost}</span>
                        )}
                    </div>


                    <div className="flex-column">
                        <label className="form-labels">Length (in KMS):
                            {/* <span className="required">*</span> */}
                        </label>
                        <input
                            type="number"
                            step="0.01"
                            name="length"
                            value={formData.length}
                            onChange={handleInputChange}
                            className={`form-input ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                        />
                        {errors.length && (
                            <span className="error-message">{errors.length}</span>
                        )}
                    </div>

                    <div className="flex-column">
                        <label className="form-labels">Workorder Date:
                            {/* <span className="required">*</span> */}
                        </label>
                        <input
                            type="date"
                            name="wo_date"
                            value={formData.wo_date}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                        />
                        {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">Remark:
                            {/* <span className="required">*</span> */}
                        </label>
                        <input
                            type="text"
                            name="remark"
                            value={formData.remark}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.remark ? 'error' : inputState.remark ? 'success' : ''}`}
                        />
                        {errors.remark && <span className="error-message">{errors.remark}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">Confirmed By:
                            {/* <span className="required">*</span> */}
                        </label>
                        <input
                            type="text"
                            name="confirm_by"
                            value={formData.confirm_by}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                        />
                        {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                    </div>

                    <div className="flex-column">
                        <label className="form-labels">Confirmation Mode:
                            {/* <span className="required">*</span> */}
                        </label>
                        <input
                            type="text"
                            name="confirmation_mode"
                            value={formData.confirmation_mode}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                        />
                        {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                    </div>

                </div>

                <div className="flex-row justify-around" >
                    <div className="button-models">
                        <button type="button" onClick={handleClose} className="model-button-cancel model-button   font-weight500  ">Cancel</button>
                    </div>
                    <div className="button-models">
                        <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                    </div>
                </div>


                <p className="error-message font-size-text margin-top40px">
                    {isSubmitted && Object.keys(errors).length > 0 && (
                        Object.keys(errors).length > 5 ? (
                            <h5 className="text-center" style={{ marginTop: "15px" }} >Please fill all mandatory fields!</h5>
                        ) : (
                            Object.keys(errors).map((field) =>
                                field.charAt(0).toUpperCase() + field.slice(1)
                            ).join(', ') + ' are required!'
                        )
                    )}
                </p>
            </form>
        </div>
    );
};


const AddClientDetails = () => {
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        email: '',
        contact_number: '',
        alt_contact_number: '',
        gstin_number: '',
        pos: '',
        contact_person: '',
        registration_certificate: "",
        pr_client: false,
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [show, setShow] = useState(false);

    const handleShow = () => {

        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        setFormData({
            name: '',
            address: '',
            email: '',
            contact_number: '',
            alt_contact_number: '',
            gstin_number: '',
            pos: '',
            contact_person: '',
            registration_certificate: "",

        })
        window.location.reload();
        setErrors({});
    }

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        // Validation rules
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }


        const requiredFields = [
            'name',
            'address',
            'email',
            'contact_number',
            'gstin_number',
            'pos',
            'contact_person',

        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        setIsSubmitted(true);


        return Object.keys(newErrors).length === 0;
    };

    const validateGSTIN = (gstin) => {
        const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}$/;
        if (!gstinPattern.test(gstin)) {
            return false;
        }
        return true;
    };
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {

            const formDataa = new FormData();
            formDataa.append('name', formData.name);
            formDataa.append('address', formData.address);
            formDataa.append('email', formData.email);
            formDataa.append('contact_number', formData.contact_number);
            formDataa.append('alt_contact_number', formData.alt_contact_number);
            formDataa.append('gstin_number', formData.gstin_number);
            formDataa.append('pos', formData.pos);
            formDataa.append('pr_client', formData.pr_client);
            formDataa.append('contact_person', formData.contact_person);
            formDataa.append('registration_certificate', formData.registration_certificate);

            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.post(`${BASE_URL_PREBID}/project/client/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })

                if (res.status === 200) {
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Client added successfully!");

                } else {
                    alert(res);
                }
            } catch (err) {
                // alert(err);
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }

        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setInputState({
            ...inputState,
            [name]: type === 'checkbox' ? (checked ? 'green' : '') : value.trim() ? 'green' : '',
        });


        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });

        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };


    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            if (file.type === 'application/pdf') {
                setFormData({
                    ...formData,
                    [name]: file,
                });
            } else {
                setErrors({
                    ...errors,
                    [name]: 'Invalid file type. Please select a PDF file.',
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: null,
            });
        }
    };


    const handleFileClear = (name) => {
        setFormData({
            ...formData,
            [name]: null,
        });
    };



    // Function to handle logo file change
    const handleLogoChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (file.type.startsWith('image/')) {
                setFormData({
                    ...formData,
                    logo: file,
                });
            } else {
                setErrors({
                    ...errors,
                    logo: 'Invalid file type. Please select an image file.',
                });
            }
        } else {
            setFormData({
                ...formData,
                logo: null,
            });
        }
    };

    // Function to clear the logo
    const handleLogoClear = () => {
        setFormData({
            ...formData,
            logo: null,
        });
    };

    console.log(errors)


    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className='modal-body-padding'>
                <form>
                    <div className='form-flex-wrap'>

                        <div className="flex-column " style={{ marginBottom: "40px" }}>
                            <div className="flex-column">
                                <label>Name:<span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.name ? 'error' : inputState.name ? 'success' : ''}`}
                                />
                                {errors.name && <span className="error-message">{errors.name}</span>}
                            </div>
                            {/* <div className="flex-row">
                                <label className="form-labels">PR Client:</label>

                                <input
                                    name="pr_client"
                                    type="checkbox"
                                    value={formData.pr_client}
                                    className={`form-checkbox ${errors.pr_client ? 'error' : inputState.pr_client ? 'success' : ''}`}
                                    onChange={handleInputChange}
                                    checked={formData.pr_client}
                                />
                                {errors.pr_client && <span className="error-message">{errors.pr_client}</span>}

                            </div> */}
                        </div>


                        <div className="flex-column">
                            <label>Email:<span className="required">*</span></label>
                            <input
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className={`form-input ${errors.email ? 'error' : inputState.email ? 'success' : ''}`}
                            />
                            {errors.email && <span className="error-message">{errors.email}</span>}
                        </div>

                        <div className="flex-column">
                            <label>GST IN Number:<span className="required">*</span></label>
                            <input
                                type="text"
                                name="gstin_number"
                                maxLength={15}
                                value={formData.gstin_number}
                                onChange={handleInputChange}
                                className={`form-input ${errors.gstin_number ? 'error' : inputState.gstin_number ? 'success' : ''}`}
                            />
                            {errors.gstin_number && (
                                <span className="error-message">{errors.gstin_number}</span>
                            )}
                        </div>

                        <div className="flex-column">
                            <label>Contact Person:<span className="required">*</span></label>
                            <input
                                type="text"
                                name="contact_person"
                                value={formData.contact_person}
                                onChange={handleInputChange}
                                className={`form-input ${errors.contact_person ? 'error' : inputState.contact_person ? 'success' : ''}`}
                            />
                            {errors.contact_person && <span className="error-message">{errors.contact_person}</span>}
                        </div>
                        <div className="flex-column">
                            <label>Contact Number:<span className="required">*</span></label>
                            <input
                                type="number"
                                min={1}
                                name="contact_number"
                                value={formData.contact_number}
                                onChange={handleInputChange}
                                className={`form-input ${errors.contact_number ? 'error' : inputState.contact_number ? 'success' : ''}`}
                            />
                            {errors.contact_number && (
                                <span className="error-message">{errors.contact_number}</span>
                            )}
                        </div>
                        <div className="flex-column">
                            <label>Alternate Contact Number:</label>
                            <input
                                type="number"
                                name="alt_contact_number"
                                value={formData.alt_contact_number}
                                onChange={handleInputChange}
                                className={`form-input ${errors.alt_contact_number ? 'error' : inputState.alt_contact_number ? 'success' : ''}`}
                            />
                            {/* {errors.altcontact_number && (
                            <span className="error-message">{errors.contact_number}</span>
                        )} */}
                        </div>


                        <div className="flex-column">
                            <label>Address:<span className="required">*</span></label>
                            <input
                                type="text"
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                className={`form-input form-control-field-long ${errors.address ? 'error' : inputState.address ? 'success' : ''}`}
                            />
                            {errors.address && (
                                <span className="error-message">{errors.address}</span>
                            )}
                        </div>


                        <div className="flex-column">
                            <label>POS:<span className="required">*</span></label>
                            <input
                                type="text"
                                name="pos"
                                value={formData.pos}
                                onChange={handleInputChange}
                                className={`form-input ${errors.pos ? 'error' : inputState.pos ? 'success' : ''}`}
                            />
                            {errors.pos && <span className="error-message">{errors.pos}</span>}
                        </div>


                        <div className="flex-column">
                            <label>Registration Certificate (PDF):</label>
                            <div className={`file-upload-container form-input ${errors.registration_certificate ? 'error' : inputState.registration_certificate ? 'success' : ''}`}>
                                <label className={`file-label ${errors.registration_certificate ? 'error' : ''}`} style={{ marginTop: "10px" }}>
                                    <input
                                        type="file"
                                        name="registration_certificate"
                                        accept=".pdf"
                                        onChange={handleFileChange}
                                        className={`file-input`}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-upload" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                        <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
                                    </svg>

                                    <span className="form-text-trasformation-uppercase" style={{ marginLeft: "20px" }}>
                                        {formData.registration_certificate ? formData.registration_certificate.name : 'No file selected'}
                                    </span>


                                    {formData.registration_certificate ? (<span className="file-clear" onClick={() => handleFileClear('registration_certificate')}>
                                        Clear
                                    </span>) : null}
                                </label>
                            </div>

                        </div>
                    </div>
                    <div className="flex-row justify-around" >
                        <div className="button-models">
                            <button type="button" onClick={handleClose} className="model-button-cancel model-button   font-weight500  ">Cancel</button>
                        </div>
                        <div className="button-models">
                            <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                        </div>
                    </div>

                    <p className="error-message font-size-text margin-top40px">
                        {isSubmitted && Object.keys(errors).length > 0 && (
                            Object.keys(errors).length > 3 ? (
                                <h5 className="text-center" style={{ marginTop: "15px" }} >Please fill all mandatory fields!</h5>
                            ) : (
                                Object.keys(errors).map((field) =>
                                    field.charAt(0).toUpperCase() + field.slice(1)
                                ).join(', ') + ' are required!'
                            )
                        )}
                    </p>
                </form>
            </div>
        </>
    );
};


const Createnewall = () => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [toggleState, setToggleState] = useState(1);

    let getDialogClassName = {}

    if (toggleState === 1) {
        getDialogClassName = 'dialog-modal-assign-task';
    } else if (toggleState === 2) {
        getDialogClassName = 'dialog-modal-add-client-details';
    } else if (toggleState === 3) {
        getDialogClassName = 'dialog-modal-add-client-details';
    }

    return (
        <>
            <button className="model-button model-button-leave font-size-heading font-weight500" onClick={handleShow}>+ Create New</button>
            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-add-client-details" >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Create New {toggleState === 1 ? "Assign Task" : toggleState === 2 ? "Project" : "Client"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <>
                        <div className="bloc-tabs-apply4">
                            <button
                                className={toggleState === 1 ? "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                    : "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading  "
                                }
                                onClick={() => setToggleState(1)}
                            >
                                Assign Task
                            </button>
                            <button
                                className={toggleState === 2 ? "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading  dashboardcomponent-active-tabs"
                                    : "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading  "}
                                onClick={() => setToggleState(2)}
                            >
                                Add Project Details
                            </button>
                            {sessionStorage.getItem("role") === "Employee" && sessionStorage.getItem("department") === "Pre Engineering" ? null :
                                <>

                                    <button
                                        className={toggleState === 3 ? "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                            : "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading  "}
                                        onClick={() => setToggleState(3)}
                                    >
                                        Add Client Details
                                    </button>
                                </>
                            }
                        </div>
                        <div className="content-tabs">
                            {toggleState === 1 ? <AssignTask /> : null}
                            {toggleState === 2 ? <AddProjectDetails /> : null}
                            {toggleState === 3 ? <AddClientDetails /> : null}
                        </div>
                    </>
                </Modal.Body>

            </Modal>
        </>
    )
}




export default Createnewall