import { Outlet } from 'react-router-dom';
import { sidebarData } from './Prebidsidebar';
import DashboardSidebar from "../../layout/DashboardSidebar";
import React, {useState} from 'react'


const EmployeeDashboard = () => {

  const [isexpand, setExpanded] = useState(false); 

  return (
    <>
      <div className="dashboard-main-section">
          <DashboardSidebar sidebarData={sidebarData}  isexpand={isexpand} setExpanded={setExpanded}/>
        <div className={isexpand ? "dashboard-main-content-sectionn" : "dashboard-main-content-sectionnn"}>
          <Outlet/>
        </div> 
      </div>
    </>
  );
};


export default EmployeeDashboard
