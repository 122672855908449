import React, { useEffect, useState } from "react";
import { BASE_URL_PREBID } from "../../config/axios";
import axios from "axios";

const NewClient = () => {

    // const [statsduration, setStatsDuration] = useState("Weekly");
    const [newclientdata, setNewclientdata] = useState([]);

    useEffect(() => {
        const getnewclientdata = async () => {
            try {
                const res = await axios.get(`${BASE_URL_PREBID}/project/firsttenclient/`);
                setNewclientdata(res.data);

            } catch (err) {
                alert(err.message);
            }
        };
        getnewclientdata();
    }, []);


    return (
        <div className="invoice-cont  height50vh width-40vw  cont-col cards-shadow2 bgwhite newclient-cont">
            <div className="justify-between cont-row align-cen bgwhite mary-10">
                <div>
                    <div className="repo-heading font-size-heading font-weight500card-heading font-sizeheading">New Client</div>
                </div>
                {/* <div className="dashboard-priorities-select" >
                <select
                    value={statsduration}
                    onChange={(e) => setStatsDuration(e.target.value)}
                    className="dashboard-sectiona-selection"
                >
                    <option value="thisweek">This Week</option>
                    <option value="today">Today</option>
                    <option value="thismonth">This Month</option>
                    <option value="thisyear">This Year</option>
                    <option value="total">Overall</option>
                </select>
                <div className="dashboard-priorities-select-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                </div>
            </div> */}
            </div>

            <div className="global-table-cont">
                <table className="custom-table">
                    <thead>
                        <tr className="custom-table-head-trr global-table-tr">
                            <th className="align-center font-size-text font-weight400">Sr no.</th>
                            <th className="align-lefft font-size-text font-weight400">Name</th>
                            <th className="align-center font-size-text font-weight400">Contact Person</th>
                            <th className="align-left font-size-text font-weight400">Contact Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {newclientdata.map((items, index) => {


                            return (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="8"></td>
                                    </tr>
                                    <tr className="custom-table-head-td global-table-td">
                                        <td className="align-center font-size-text font-weight400">{index + 1}</td>
                                        <td className="align-lefft font-size-text font-weight400">{items.name}</td>
                                        <td className="align-center font-size-text font-weight400">{items.contact_person}</td>
                                        <td className="align-left font-size-text font-weight400">{items.contact_number}</td>


                                    </tr>
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default NewClient