import React, { useRef, useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import axios from 'axios'
import { BASE_URL_PREBID } from '../../config/axios';
import { useParams } from 'react-router-dom';
import { formattedDateLong } from '../Criticalissue/Date'
import numberToWords from 'number-to-words';
import { toast, ToastContainer } from 'react-toastify';
import { Modal } from 'react-bootstrap';

const SalarySlip = React.forwardRef((props, ref) => {

  const [invoiceData, setInvoiceData] = useState({
    "project": {},
    "client": {},
    "invoice": {},
  })
  const projectData = [
    {
      name: "Max",
      food: 100.0,
      fill: "#fff",
    },
  ]

  let { id } = useParams();
  const [match, setMatch] = useState(false);
  const [word, setWord] = useState("");
  const invoiceGSTIN = "06AAHCC0747L1ZK";


  const getInvoiceData = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL_PREBID}/project/invoicedetails/${id}/`);
      setInvoiceData(res.data)

      const firstTwoCharactersRGSTIN = res.data?.client?.gstin_number?.substring(0, 2);
      const firstTwoCharactersIGSTIN = invoiceGSTIN.substring(0, 2);
      setWord(numberToWords.toWords(res.data.invoice.billamount))
      // const gstinMatch = firstTwoCharactersRGSTIN === firstTwoCharactersIGSTIN;
      if (firstTwoCharactersRGSTIN === firstTwoCharactersIGSTIN) {
        setMatch(true);
      }
    } catch (err) {
    }
  };


  useEffect(() => {
    getInvoiceData(id);



  }, []);


  return (
    <div className='salaryslip' ref={ref}>
      <div className='salaryslip'>
        <div className='salaryslip-header'>
          <div className='salaryslip-header-left'>
            <img src="https://cipl-aimantra.s3.ap-south-1.amazonaws.com/Logos/Cipl+Logo++(1).png" alt='logo' className='salaryslip-logo' />

            {/* <img src={Logo} alt='logo' className='salaryslip-logo' /> */}
            <div className='salaryslip-header-left-text'>
              PERFORMA INVOICE
            </div>
          </div>
          <div className='salaryslip-header-right'>
            <div className='salaryslip-header-right-top'>
              Civilmantra infracon private limited
            </div>
            <div className='salaryslip-header-right-bottom'>
              3rd Floor, Tower 3A,<br /> DLF Corporate Greens, Sector 74A,<br /> Gurugram, Haryana 122004 <br /> services@civilmantra.com
            </div>
          </div>

        </div>
        <div className='salaryslip-hr'></div>
        <div className='salaryslip-header-second slip-column-2heads'>


          <div className='salaryslip-heading-black'>
            Recipient Detail
          </div>
          <div className=' salaryslip-heading-black'>
            Invoice Detail
          </div>
        </div>
        <div className='salaryslip-header-second'>


          <table className='salary-table'>
            <tbody>
              {/* <br /> */}

              <tr>
                <td className='salaryslip-heading-black font-weight500'>Name</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1 font-weight700'>{invoiceData.client.name}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>Address</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1'>{invoiceData.client.address}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>Email</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1 '>{invoiceData.client.email}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>Contact No.</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1 '>{invoiceData.client.contact_number}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>POS</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1'>{invoiceData.client.pos}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>GSTIN</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1'>{invoiceData.client.gstin_number}</td>
              </tr>



              <br />

              {/* <tr>
                    <td colSpan={65} className='salaryslip-horizontal-division'></td>
                  </tr> */}
              {/* <br /> */}

            </tbody>
          </table>

          <div className='salaryslip-vertical-division2'></div>
          {/* <div className='salaryslip-vertical-division'></div> */}


          <table className='salary-table'>
            <tbody>
              {/* <br /> */}

              <tr>
                <td className='salaryslip-heading-black font-weight500'>Invoice no.</td>
                <td className='salaryslip-heading-black1 font-weight700'>{invoiceData.invoice.invoice_number}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>Invoice Date</td>
                <tr>
                <td className='salaryslip-heading-black1'>{formattedDateLong(invoiceData.invoice.billing_date)}</td>
                <UpdateInvoiceDate i={invoiceData} getInvoice={getInvoiceData}/>
                </tr>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>CIPL Project no.</td>
                <td className='salaryslip-heading-black1'>{invoiceData.project.prebid_prcode}</td>
              </tr>
              {/* <tr>
                <td className='salaryslip-heading-black font-weight500'>Running Invoice</td>
                <td className='salaryslip-heading-black1'>{invoiceData.client.gstin_number}</td>
              </tr> */}
              <tr>
                <td className='salaryslip-heading-black font-weight500'>PAN</td>
                <td className='salaryslip-heading-black1'>AAHCC0747L</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>Account No.</td>
                <td className='salaryslip-heading-black1'>50200026004051</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>IFSC</td>
                <td className='salaryslip-heading-black1'>HDFC0009090</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>GSTIN</td>
                <td className='salaryslip-heading-black1'>{invoiceGSTIN}</td>
              </tr>



              {/* <br /> */}

              {/* <tr>
                    <td colSpan={65} className='salaryslip-horizontal-division'></td>
                  </tr> */}
              {/* <br /> */}

            </tbody>
          </table>

        </div>
        <div className='salaryslip-hr'></div>
        {/* <br /> */}
        <tr className="salaryslip-division  ">
          <td ><th>Name of Work:</th>
            {invoiceData.project.project_name}
            <th>- Pre Tender Engineering Services</th></td>
        </tr>



        <div className='salaryslip-body'>
          <table className='salary-table'>
            <thead>
              <tr className='expenseslip-head'>
                <th className="expense-table-td expenseslip-head-data expenseslip-row-data1">Sn</th>
                <th className="expense-table-td expenseslip-row-data2">Project particulars</th>
                <th className="expense-table-td expenseslip-row-data3">Work Order</th>
                <th className="expense-table-td expenseslip-row-data4">Rate %</th>
                <th className="expense-table-td expenseslip-row-data4">Amount</th>

              </tr>
            </thead>
            <tbody>
              <React.Fragment>
                <tr className='expense-table-row'>
                  <td className='expense-table-td expenseslip-row-data1'>{1}</td>
                  <td className='expenseslip-row-data2'>{invoiceData.invoice.work_stages}</td>
                  <td className='expense-table-td expenseslip-row-data3'>{invoiceData.project.without_gst_amount}</td>
                  <td className='expense-table-td expenseslip-row-data4'>{invoiceData.invoice.bill_percentage}%</td>
                  <td className='expense-table-td-r expenseslip-row-data4 align-center'>{invoiceData.invoice.wihoutgst_amount}</td>
                </tr>
                <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr>

                {/* <tr className='expense-table-row'>
                        <td className='expense-table-td expenseslip-row-data1'>{index + 1}</td>
                        <td className='expenseslip-row-data2'>kjh oasdhfskdjfhkj f fsd fsd fsdfsdf sdf dsf sdfsdf sdf sd fd </td>
                        <td className='expense-table-td expenseslip-row-data3'>28000</td>
                        <td className='expense-table-td expenseslip-row-data4'>28000</td>
                        <td className='expense-table-td expenseslip-row-data3'>200</td>
                      </tr>
                      <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}

              </React.Fragment>

              {/* <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}
              {/* <tr> */}
              {/* <td ></td> */}
              {/* <td className='expenseslip-row-data2'><b>Payment Stage -</b> Advance 25%</td> */}
              {/* </tr> */}
              {/* <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}
              {/* <tr>
                <td ></td>
                <td className='expenseslip-row-data2'><b></b> </td>
                <td className="expense-table-td-r expenseslip-row-data3"> </td>
                <td className="expense-table-td-r expenseslip-row-data4"></td>
                <td className="expense-table-td-r expenseslip-row-data4"> </td>
              </tr> */}
              <br />
              <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr>

              {/* <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}
              {/* <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}


            </tbody>
          </table>
          {/* <div className="salaryslip-body"> */}
          <table className='salary-table'>

            {/* <thead>
                  <tr className='expenseslip-head'>
                    <th className="expense-table-td expenseslip-head-data expenseslip-row-data1">Sn</th>
                    <th className="expense-table-td expenseslip-row-data5">Project particulars</th>
                    <th className="expense-table-td expenseslip-row-data6">Amount</th>
                    <th className="expense-table-td expenseslip-row-data3">Work Order</th>
                    <th className="expense-table-td expenseslip-row-data4">Rate %</th>
                    <th className="expense-table-td expenseslip-row-data3">Amount</th>
                  </tr>
                </thead> */}

            <tbody>
              <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr>


              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'><b>Total Amount Before Tax</b></td>
                <td className='expense-table-td expenseslip-row-data6'></td>
                <td className='expense-table-td-r expenseslip-row-data3'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td-r expenseslip-row-data4'><b>{invoiceData.invoice.wihoutgst_amount}</b></td>
              </tr>
              <tr className='expense-table-row'>
                <td className='expense-table-td expenseslip-head-data expenseslip-row-data1'></td>
                <td className='expenseslip-row-data5'><b>Work Order Summary -</b> </td>
                <td className='expense-table-td expenseslip-row-data6'><b>Amount</b> </td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>IGST</b></td>
                <td className='expense-table-td-r expenseslip-row-data4'>18%</td>
                <td className="expense-table-td expenseslip-row-data4">{match ? "---" : invoiceData.invoice.gst_amount}</td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'>Total Value of Work Order</td>
                <td className='expense-table-td expenseslip-row-data6'>{invoiceData.project.without_gst_amount}</td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>SGST  </b></td>
                <td className='expense-table-td-r expenseslip-row-data4'>9%</td>
                <td className='expense-table-td expenseslip-row-data4'>{!match ? "---" : (invoiceData.invoice.gst_amount / 2)}</td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'>Total Billing Up to Previous Invoice</td>
                <td className='expense-table-td expenseslip-row-data6'>{invoiceData.total_billing_up_to_previous < 0 ? "-" : invoiceData.total_billing_up_to_previous}</td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>CGST</b></td>
                <td className='expense-table-td-r expenseslip-row-data4'>9%</td>
                <td className='expense-table-td expenseslip-row-data4'>{!match ? "---" : (invoiceData.invoice.gst_amount / 2)}</td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'>Total Billing in Current Invoice</td>
                <td className='expense-table-td expenseslip-row-data6'>{invoiceData.invoice.wihoutgst_amount}</td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>Total Tax</b></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td expenseslip-row-data4'>{invoiceData.invoice.gst_amount}</td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'><b>Balance to Bill</b></td>
                <td className='expense-table-td expenseslip-row-data6'><b>

                  {/* {invoiceData.balance_to_bill} */}
                  {invoiceData.project.without_gst_amount - (invoiceData.total_billing_up_to_previous < 0 ? 0 : invoiceData.total_billing_up_to_previous) - invoiceData.invoice.wihoutgst_amount}

                </b></td>
                <td className='expense-table-td-r expenseslip-row-data3'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'><b>Total Amount After Tax</b></td>
                <td className='expense-table-td expenseslip-row-data6'></td>
                <td className='expense-table-td-r expenseslip-row-data3'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td-r expenseslip-row-data4'><b>{invoiceData.invoice.billamount}</b></td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>

              {/* <br /> */}
            </tbody>


          </table>


          <tr className='expense-table-row'>
            <td className="expense-table-td expenseslip-row-data1"> </td>
            <td className=' '><b style={{ textTransform: "capitalize" }}>{word} Only</b></td>
          </tr>
          <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
          <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>


        </div>
        {/* <div className='salaryslip-hr'></div> */}
        <div className='salaryslip-header-third'>

          <div>
            <th>Terms and Conditions:-</th>
            {/* <td>Terms and Conditions:-</td> */}
            <h6 className='TnC'>1. Invoice is True and fair </h6>
            <h6 className='TnC'>2. All disputes belong to Haryana Jurisdiction </h6>
            <h6 className='TnC'>3. Payment Shall be made witin 5 Days of </h6>
            <h6 className='TnC'>4. We are Registered MSME Unit Vide No.- UDYAM-DL-11-0010217 </h6>
            <h6 className='TnC'><b>5. This is Software generated Invoice no signature required</b></h6>

          </div>
          {/* <div className='salaryslip-bottom-signature'>
            <th>For CivilMantra Infracon Pvt Ltd</th>
            <img src={"Signature"} alt='signature' className='slaryslip-bg-signature' />
            <th>AUTHORISED SIGNATURE</th>
          </div> */}
        </div>
      </div>
      <div className='slaryslip-bg'>
      </div>
    </div>
  );
});

const UpdateInvoiceDate= ({ getInvoice,i}) => {
  // let { id } = useParams();

console.log("billing date ")

console.log(`${i?.invoice?.id}`);

  //   ****************   Modal Section
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
      setShow(true);
      setFormData(i?.invoice?.billing_date);
  };
  const [loading, setLoading] = useState(false); //loading logic
  const [formData, setFormData] = useState({
    billing_date:"null"
  });
  const [errors, setErrors] = useState({});

  const [inputState, setInputState] = useState({});

  const validateForm = () => {
      const newErrors = {};

      const requiredFields = [
          'billing_date'
      ];

      requiredFields.forEach(field => {
          if (!formData[field]) {
              newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
          }
      });

      setErrors(newErrors);

      return Object.keys(newErrors).length === 0;
  };






  const handleSubmit = async (e) => {
      e.preventDefault();

      if (validateForm()) {


      
          const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

          try {
              let res = await axios.put(`${BASE_URL_PREBID}/project/invoiceUpdate/${i?.invoice?.id}/`, formData,)

              if (res.status === 200) {
                 await getInvoice();
                 handleShow();
              } else {
                  alert(res);
              }
          } catch (err) {

              //toast Logic
              if (err.response) {
                  toast.dismiss(loadingToastId);
                  const errorData = err.response.data;

                  if (typeof errorData.error === 'string') {
                      // Single error message
                      toast.error(`Error: ${errorData.error}`);
                  } else if (typeof errorData === 'object' && errorData !== null) {
                      // Multiple error messages
                      Object.entries(errorData).forEach(([field, messages]) => {
                          messages.forEach(message => toast.error(`"${field}": ${message}`));
                      });
                  } else {
                      toast.error('Error:- Failed to Process!');
                  }
              } else {
                  toast.error('Error processing your request.');
              }
          } finally {
              setLoading(false); //loading logic
              toast.dismiss(loadingToastId);
          }
      } else {
          console.log('Form contains errors:', errors);
      }
  };

  const handleInputChange = (e) => {
      const { name, value } = e.target;

      setInputState({
          ...inputState,
          [name]: value.trim() ? 'green' : '',
      });
    
          setFormData({
              ...formData,
              [name]: value,
          });
  };










  return (
      <>

          <button className='upload-svga' onClick={handleShow}>

              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  fill='white'
                  id="edit">
                  <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path><path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path></svg>
          </button>




          <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-assign-task dialog-model" >

              <Modal.Header closeButton>
                  <Modal.Title className="modal-title">Update Billing Date </Modal.Title>
              </Modal.Header>
              <Modal.Body>

                  <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />


                  <div className='modal-body-padding'>
                      <form>
                          <div className='form-flex-wrap'>
                              <div className="flex-column">
                                  <label className="form-labels">Billing Date <span className="required">*</span></label>
                                  <input
                                      type="date"
                                      name="billing_date"
                                      value={formData.billing_date}
                                      onChange={handleInputChange}
                                      className={`form-input ${errors.billing_date ? 'error' : inputState.billing_date ? 'success' : ''}`}
                                  />
                                  {errors.billing_date && <span className="error-message">{errors.billing_date}</span>}
                              </div>
                          </div>

                          <div className="button-models">
                              <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                          </div>
                      </form>
                  </div>

              </Modal.Body>

          </Modal>

      </>
  );
};


const PerformaInvoice = () => {
  const ref = useRef();

  return (
    <div>
      {/* Your other components or content */}
      <UpdateInvoiceDate ref={ref}/>
      <ReactToPrint
        bodyClass="print-agreement"
        content={() => ref.current}
        trigger={() => (
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
              <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
            </svg> Print
          </button>
        )}
      />

      <SalarySlip ref={ref} />
    </div>
  );
};

export { PerformaInvoice ,UpdateInvoiceDate};