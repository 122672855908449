import { useLocation, Navigate, Outlet } from "react-router-dom";

import React from "react";

const Auth = ({ allowedRoles }) => {
  let role = sessionStorage.getItem("role");
  let token = sessionStorage.getItem("access_token");
  const location = useLocation();

  return role === allowedRoles ? (
    <Outlet />
  ) : token ? (
    <Navigate to="unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="login" state={{ from: location }} replace />
  );
};

export default Auth;
