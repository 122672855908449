import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import { sidebarData } from './Account_sidebar';

import Navbar from '../../layout/Dashboardnavbar';
import DashboardSidebar from "../../layout/DashboardSidebar";

const AccountDashboard = () => {

  const [isexpand, setExpanded] = useState(false);
  return (
    <>
      <div className="dashboard-main-section">
        <DashboardSidebar sidebarData={sidebarData} isexpand={isexpand} setExpanded={setExpanded} />
        <div className={isexpand ? "dashboard-main-content-sectionn" : "dashboard-main-content-sectionnn"}>

          <Outlet />
        </div>
      </div>
    </>
  )
}

export default AccountDashboard