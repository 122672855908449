import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BASE_URL_PREBID } from "../../config/axios";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Updatestages = ({ id }) => {
  //   ****************   Modal Section
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getClientDetails();
  };

  const [allWorkStagesStats, setAllWorkstagesStatus] = useState([]);

  const getClientDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL_PREBID}/project/workstagesbyproject/${id}/`
      );
      setAllWorkstagesStatus(res.data);
      setInputFields(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const [inputFields, setInputFields] = useState([{}]);

  console.log(inputFields);

  const handleChange = (index, e) => {
    const { name, checked } = e.target;
    const updatedFields = [...inputFields]; // Assuming inputFields is your state holding the array of objects
    updatedFields[index][name] = checked; // Update only the completed field of the object at the specified index
    setInputFields(updatedFields);
  };
  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(
        `${BASE_URL_PREBID}/project/workstagesfilter/${id}/`,
        inputFields,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );

      if (res.status === 200) {
        window.location.reload();
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className='upload-svga' onClick={handleShow}>

        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" enable-background="new 0 0 32 32" viewBox="0 0 32 32" fill='white' id="add"><path d="M2.4375,17.9375h11.625v11.625c0,1.06854,0.86896,1.9375,1.9375,1.9375s1.9375-0.86896,1.9375-1.9375v-11.625h11.625c1.06854,0,1.9375-0.86896,1.9375-1.9375s-0.86896-1.9375-1.9375-1.9375h-11.625V2.4375C17.9375,1.36896,17.06854,0.5,16,0.5s-1.9375,0.86896-1.9375,1.9375v11.625H2.4375C1.36896,14.0625,0.5,14.93146,0.5,16S1.36896,17.9375,2.4375,17.9375z"></path></svg>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dialog-modal-assign-task-small"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Update Stages </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              {allWorkStagesStats.map((data, index) => (
                index !== 0 && ( // Skip rendering for the first element
                  <>
                    <h3 className="dashboard-herosection-left-h4">
                      Stage {index}
                    </h3>
                    <div className="field-cont">
                      <div className="flex-column form-group-select">
                        <label className="form-labels">Stage Name:</label>

                        <input
                          name="workStages"
                          type="text"
                          value={data.workStages}
                          className="form-input-modal"
                          readOnly={data.completed}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>

                      <div className="flex-row">
                        <label className="form-labels">Completed:</label>
                        {data.completed ? (
                          <input
                            name="completed"
                            type="checkbox"
                            value={data.completed}
                            checked={data.completed}
                            readOnly
                            className="form-checkbox"
                          />
                        ) : (
                          <input
                            type="checkbox"
                            name="completed"
                            value={data.completed}
                            checked={data.completed}
                            onChange={(e) => handleChange(index, e)}
                            className="form-checkbox"
                          />
                        )}
                      </div>
                    </div>
                  </>
                )
              ))}


              <form>{/* ...other form elements */}</form>
            </div>

            <div className="button-models">
              <button
                type="submit"
                className="model-button model-button-submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Updatestages;
