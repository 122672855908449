import axios from "axios";
import { useState } from "react";
import { Link } from 'react-router-dom'
import { BASE_URL } from "../../config/axios";
import Logo from "../../assets/images/website/forgotpass.png"


const Login = () => {
const [errMsg, setErrMsg] = useState("");
const currentUrl = window.location.href;
const urlParts = currentUrl.split('/');
const baseUrl = urlParts[0] + `//` + urlParts[2];
const [errors, setErrors ] = useState({});
const [inputState, setInputState] = useState({});

const [formData, setFormData] = useState({
  email: "",
  url: baseUrl
});


const validateForm = () => {
  const newErrors = {};
  if (!formData.email.trim()) {
    newErrors.email = 'Email is required';
  } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
  }
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};


const handleSubmit = async (e) => {
  e.preventDefault();
  if (validateForm()) {
    try {
      let res = await axios.post(`${BASE_URL}/user/forgotpassword/`, formData);
      if (res.status === 200) {
          // Open Gmail in a new tab/window
          window.open("https://mail.google.com/", "_blank");
        } 
        else {
        setErrMsg("Invalid Email")
      }
    } catch (err) {
      setErrMsg("Invalid Email")
    }
  } else {
    console.log('Form contains errors:', errors);
  }
};


const handleInputChange = (e) => {
  const { name, value } = e.target;
  setInputState({
    ...inputState,
    [name]: value.trim() ? 'green' : '',
  });
  setFormData({
    ...formData,
    [name]: value,
  });
};


return (
  <>
    <div className='login-page'>
      <div>
        <Link to="/">
          <img src={Logo} alt='logo' className='login-page-img' /></Link>
      </div>
      <div className='login-page-right'>  
        <div className='login-page-second-heading'>Forgot Password</div> 
        <form onSubmit={handleSubmit} className="register-form">
          <div className="flex-column">
            <label htmlFor="email" className='form-labels'>Email</label>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="example@gmail.com"
              autoComplete="off"
              onChange={handleInputChange}
              value={formData.email}
              className={`form-input ${errors.email ? 'error' : inputState.email ? 'success' : ''}`}
            />
             {errors.email && (<span className="error-message">{errors.email}</span>)}</div>
         
          <div>
            <button type="submit" className="login-button" >
              Reset Password
            </button></div>
          <p
            style={{ color: "red" }}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
        </form>
      </div>
    </div>
  </>
  )
}

export default Login