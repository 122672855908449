
const formatDate = (dateString,) => {

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
    const date = new Date(dateString);
  
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      timeZone: userTimeZone,
    };
  
    return date.toLocaleString('en-US', options);
  };
  
  
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  
  var datearray = formattedDate.split("-");
  
  
  const formattedDateLong = (date) =>
  
    new Date(date).toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  
  const formattedDateNoYear = (date) =>
  
    new Date(date).toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
    });
  
  
  export { formatDate, formattedDate, datearray, formattedDateLong, formattedDateNoYear }