import React from 'react'
import PNF from "../assets/images/pnf.png" 

const PageNotFound = () => {
  return (
    <>
      <img className='pagenotfound' src={PNF} alt="Error 404 - Page Not Found"/>
    </>
  )
}

export default PageNotFound