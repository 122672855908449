import axios from "axios";
import { useState } from "react";
import { BASE_URL_PREBID } from "../../config/axios";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";



const UpdateClientDetails = ({ i, getJobappdata }) => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setErrors({});
        setShow(false);
    }

    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleShow = () => {
        setShow(true);
        setFormData(i);
    }


    const [formData, setFormData] = useState({
        name: '',
        address: '',
        email: '',
        contact_number: '',
        alt_contact_number: '',
        gstin_number: '',
        pos: '',
        contact_person: '',
    });





    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        // Validation rules
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }

      


        const requiredFields = [
           'name',
            'address',
            'email',
            'contact_number',
            'gstin_number',
            'pos',
            'contact_person',

        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        setIsSubmitted(true);


        return Object.keys(newErrors).length === 0;
    };

    const validateGSTIN = (gstin) => {
        // GSTIN format: AAABBBCCCCCDDDE
        const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}$/;



        if (!gstinPattern.test(gstin)) {
            return false;
        }

        // Perform GSTIN verification here (if needed)

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {

            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL_PREBID}/project/clientupdate/${i.id}/`, formData, {
                })

                if (res.status === 200) {
                    await getJobappdata();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Client details updated successfully!");

                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                toast.dismiss(loadingToastId);
            }

        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value,
        });

        setFormData({
            ...formData,
            [name]: value,
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };












    return (

        <>
            <button className="model-edit-button" title="Update Details" onClick={handleShow} >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>



            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-add-client-details">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-body-padding'>
                        <form>
                            <div className='form-flex-wrap'>


                                <div className="flex-column">
                                    <label>Name:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.name ? 'error' : inputState.name ? 'success' : ''}`}
                                    />
                                    {errors.name && <span className="error-message">{errors.name}</span>}
                                </div>


                                <div className="flex-column">
                                    <label>Email:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.email ? 'error' : inputState.email ? 'success' : ''}`}
                                    />
                                    {errors.email && <span className="error-message">{errors.email}</span>}
                                </div>

                                <div className="flex-column">
                                    <label>GST IN Number:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="gstin_number"
                                        value={formData.gstin_number}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.gstin_number ? 'error' : inputState.gstin_number ? 'success' : ''}`}
                                    />
                                    {errors.gstin_number && (
                                        <span className="error-message">{errors.gstin_number}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label>Contact Person:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="contact_person"
                                        value={formData.contact_person}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.contact_person ? 'error' : inputState.contact_person ? 'success' : ''}`}
                                    />
                                    {/* {errors.contact_person && <span className="error-message">{errors.contact_person}</span>} */}
                                </div>
                                <div className="flex-column">
                                    <label>Contact Number:<span className="required">*</span></label>
                                    <input
                                        type="number"
                                        min={1}
                                        name="contact_number"
                                        value={formData.contact_number}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.contact_number ? 'error' : inputState.contact_number ? 'success' : ''}`}
                                    />
                                    {errors.contact_number && (
                                        <span className="error-message">{errors.contact_number}</span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label>Alternate Contact Number:</label>
                                    <input
                                        type="number"
                                        name="alt_contact_number"
                                        value={formData.alt_contact_number}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.alt_contact_number ? 'error' : inputState.alt_contact_number ? 'success' : ''}`}
                                    />
                                    {/* {errors.altcontact_number && (
                            <span className="error-message">{errors.contact_number}</span>
                        )} */}
                                </div>


                                <div className="flex-column">
                                    <label>Address:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                        className={`form-input form-control-field-long ${errors.address ? 'error' : inputState.address ? 'success' : ''}`}
                                    />
                                    {errors.address && (
                                        <span className="error-message">{errors.address}</span>
                                    )}
                                </div>


                                <div className="flex-column">
                                    <label>POS:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="pos"
                                        value={formData.pos}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.pos ? 'error' : inputState.pos ? 'success' : ''}`}
                                    />
                                    {errors.pos && <span className="error-message">{errors.pos}</span>}
                                </div>



                            </div>
                            <div className="button-models flex-row justify-around">
                                <button type="button" onClick={handleClose} className="model-button model-button-cancel">Cancel</button>
                                <button onClick={handleSubmit} className="model-button model-button-submit">Update</button>
                            </div>

                            <p className="error-message font-size-text">
                        {isSubmitted && Object.keys(errors).length > 0 && (
                            Object.keys(errors).length > 5 ? (
                                <h5 className="text-center" style={{ marginTop: "15px" }} >Please fill all mandatory fields!</h5>
                            ) : (
                                Object.keys(errors).map((field) =>
                                    field.charAt(0).toUpperCase() + field.slice(1)
                                ).join(', ') + ' are required!'
                            )
                        )}
                    </p>

                        </form>
                        <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    </div>

                </Modal.Body>
            </Modal></>
    );
};


export { UpdateClientDetails }