import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { formattedDateLong } from "../../Date";
import { AUTH, BASE_URL } from "../../../config/axios";
import { handleErrorToast } from "../../custom";

const ViewBill = ({ i }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|gif|bmp|webp)(\?.*)?$/.test(url);
  }
  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button title={"View Bill"} onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 16.933 16.933"
          id="bill">
          <path
            d="M1.854 0C.819 0 0 .83 0 1.852c-.002 4.94.002 9.878.002 14.816 0 .221.256.345.429.207l1.488-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.488-1.19 1.49 1.19a.265.265 0 0 0 .33 0l1.489-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.487-1.19 1.488 1.19c.174.139.43.015.43-.207l.002-8.733h2.91a.26.26 0 0 0 .263-.263V1.854A1.85 1.85 0 0 0 15.083 0Zm0 .53h11.932c-.35.337-.556.8-.556 1.324l-.002 14.265-1.222-.98a.265.265 0 0 0-.33 0l-1.49 1.191-1.488-1.191a.264.264 0 0 0-.33 0L6.878 16.33 5.391 15.14a.265.265 0 0 0-.33 0L3.575 16.33 2.085 15.14a.264.264 0 0 0-.33 0l-1.224.98L.53 1.852c0-.77.634-1.322 1.324-1.322zm13.228 0c.73 0 1.324.586 1.324 1.324v5.554H13.76V1.854c0-.738.592-1.324 1.322-1.324zM3.707 3.439c-.133 0-.265.089-.265.266v.287c-.599.116-1.058.63-1.058 1.252 0 .42.217.672.48.799.264.127.552.147.816.174.265.027.506.06.639.125.133.064.181.15.181.32 0 .406-.34.743-.782.746-.384-.014-.658-.23-.784-.623-.106-.339-.614-.175-.503.162.152.475.48.844 1.011.96v.294c0 .354.53.354.53 0v-.282c.598-.116 1.058-.635 1.058-1.257 0-.388-.18-.654-.48-.799-.264-.126-.552-.146-.817-.173-.264-.028-.502-.056-.639-.126-.122-.062-.181-.142-.181-.32 0-.41.34-.742.783-.745.421.016.666.26.783.622.107.339.615.175.504-.162-.152-.474-.5-.857-1.012-.959v-.295c0-.177-.132-.266-.264-.266zm2.645.265c-.355 0-.355.527 0 .53h4.763c.353 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.53 0 .53h4.763c.352 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.527 0 .527h4.763c.352 0 .352-.527 0-.527zM2.649 9.26c-.356 0-.356.537 0 .529h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.53 0 .53h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.529 0 .53h8.466c.352 0 .352-.53 0-.53z"
            fill="#2576BC"
          >
          </path>
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Bill of: {i.item_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="flex-row justify-center">
              {/* <img className="bill-img" src={i.bill} alt={`Bill Image of ${i.item_name}`} /> */}
              {isImage(i.bill) ? (
                <img className="bill-img" src={i.bill} alt={`Bill Image of ${i.item_name}`} />
              ) : (
                <a href={i.bill} target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-file-pdf" viewBox="0 0 16 16" color="#FF747C">
                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                    <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                  </svg>
                  Open Bill Document
                </a>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const UpdateBill = ({ i, getExpenses, getSpecificExpenses, date }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {

    setFormData({ bill: "" });
    handleClear();
    setShow(false)
  };
  const handleShow = () => {
    // setFormData(i)
    setShow(true);
  }

  const [formData, setFormData] = useState({
    bill: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["bill"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };


  const [fileName, setFileName] = useState("");
  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, [name]: file, });
      setFileName(file.name);
    } else {
      setFileName("");
    }
  };

  const handleClear = () => {
    setFormData(prevState => ({
      ...prevState,
      bill: ""
    }));
    setFileName("");
  }

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const formatttedDatee = `${day.toString().padStart(2, "0")}`;
  const [selecteddate, setSelecteddate] = useState(`${formatttedDatee}`);
  const [monthh, setMonthh] = useState(`${month}`);
  const [yearr, setYearr] = useState(`${year}`);


  const [loading, setLoading] = useState(false); //loading logic
  const handleBillUpload = async (e) => {
    e.preventDefault();
    if (validateForm()) {

      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = formData.bill;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, { type: originalFile.type });

      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/expensedetailsupdate/${i.id}/`, {
          bill: customFile,
          bill_approved: 'pending',
          bill_approved_amt: i.tl_approved_amt,
        }, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          await getExpenses(date);
          await getSpecificExpenses();
          handleClose();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button title="Upload Bill" className=" model-button-print" onClick={handleShow} >
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 22" id="upload">
          <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
          </g>
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Upload Bill for: {i.item_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="flex-row justify-center">
              {/* <img className="bill-img" src={i.bill} alt={`Bill Image of ${ i.item_name }`} /> */}

              <div className="flex-column">
                <label
                  htmlFor="bill"
                  // className="form-labels announce-date font-weight500  form-inputss"
                  className="form-labels   font-weight500    announce-date font-size-heading"
                  style={{ width: '8vw' }}
                >
                  Attach Bill
                </label>
                <label
                  title={`${fileName && `${fileName}`}`}
                  htmlFor="file-upload"
                  className={`custom - doc - upload ${errors.bill
                    ? "error"
                    : inputState.bill
                      ? "success"
                      : ""
                    } `}
                >
                  <div htmlFor="file-upload" className="">
                    <svg
                      className="pdf-input"
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                        stroke="#707070"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </label>
                <input
                  id="file-upload"
                  type="file"
                  name="bill"
                  onChange={handleFileChange}
                  style={{ display: "none", position: "relative" }}
                  className="width-5vw"
                />

                <div className="justify-evenly">
                  <div>
                    <br />
                    {fileName && <p className="width-10vw">{fileName}</p>}
                  </div>

                  {formData.bill !== "" ?
                    <div>
                      <br />
                      <button className="file-clear  model-button-cancel" onClick={handleClear}>Clear</button>
                    </div>
                    : null
                  }
                </div>
                {errors.bill && (
                  <span className="error-message font-size-text ">{errors.bill}</span>
                )}
              </div>

            </div>

            <div className="button-models">
              <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button className="model-button-download model-button font-weight500" onClick={handleBillUpload}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                  <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                  </g>
                </svg>
                {"  "} Upload
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export {
  ViewBill,
  UpdateBill,
}