import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbar";
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios";
import { UpdateProject } from "../Modals/Allmodals";
import Updatestages from "../Modals/Updatestages.jsx";
import { ToastContainer, toast } from "react-toastify";
import { formattedDateLong } from "../Criticalissue/Date.jsx";
import { Modal } from "react-bootstrap";
import { StagesManagement } from "../Modals/Addstages.jsx";
import { formatCurrencyIndian } from "../custom.jsx";



const DeleteProjectDocuments = ({
  id,
  getJobappdata,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      console.log("Delete Expense Detail Api");
      let res = await axios.delete(
        `${BASE_URL}/project/projectupdate/${id.pr_code}/`
      );

      if (res.status === 200) {
        await getJobappdata("null");
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {

      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are You Sure, Your want to Delete
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          <br />
          {id.prebid_prcode} - {id.project_name}

          <div className="button-models">

            <button
              onClick={handleFormSubmitDelete}
              className="model-button model-button-cancel"
            >
              Delete
            </button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

const AllProjectDetails = () => {
  const location = useLocation();
  const [allClientsData, setAllClientsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [filtersData, setFiltersData] = useState([]);
  const [prProjects, setPrProjects] = useState("null");
  const [selectedProject, setSelectedProject] = useState("null");
  const [selectedClient, setSelectedClient] = useState("null");

  const [buffer, setBuffering] = useState(true); //buffering logic

  const [toggleState, setToggleState] = useState(
    location.state?.status || "null"
  );
  const role = sessionStorage.getItem('role');
  const department = sessionStorage.getItem('department');

  const getFilterProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL_PREBID}/project/filterprproject/false/`);
      setFiltersData(res.data);



    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }

  };

  useEffect(() => {
    getFilterProjects();
  }, []);

  const getJobappdata = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL_PREBID}/project/projectwithoutpr/${selectedClient}/false/${selectedProject}/`);
      setAllClientsData(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }

  };

  useEffect(() => {
    getJobappdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, selectedProject]);



  useEffect(() => {
    getEmployeeDetails();
  }, []);

  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const getEmployeeDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysdep/${sessionStorage.getItem("departmentid")}/`
      );
      setAllEmployeeData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };
  const [initiated, setInitiated] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (toggleState === "null") {
      setFilteredData(allClientsData); // Show all data
    } else {
      const filtered = allClientsData.filter(
        (item) => item.status === toggleState
      );
      setFilteredData(filtered);
    }
  }, [toggleState, allClientsData]);

  return (
    <>

      <Dashboardnavbarcopy url="All Projects" name={"All Projects"} />

      <div className="content-tabs">
        <div className="attendance-subcont">
          <div className="field-cont">
            <div title="Project Filter " className="field-cont-div ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <select
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.target.value)}
                className={`attendance-input-field width-15vw`}
              >
                <option value="null">Select Project</option>
                {filtersData?.sort((a, b) => a.project_short_name?.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                  <option key={index} value={i.pr_code}>{i.prebid_prcode}-{i.project_short_name}</option>
                ))}
              </select>
              <hr className="field-cont-hr" />

            </div>
            <div title="Client Filter " className="field-cont-div ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <select

                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                className={`attendance-input-field width-15vw`}
              >

                <option value="null">Select Client</option>
                {filtersData?.sort((a, b) => a.client_name?.localeCompare(b.client_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                  <option key={index} value={i.client}>{i.client_name}</option>
                ))}
              </select>
              <hr className="field-cont-hr" />

            </div>




          </div>
        </div>
        <div className="attendance-box">
          <div className="datagrid-container">
            <div className="custom-table-background height-73vh">
              <div className="table-heading-flex">
                <div className="repo-heading font-size-heading font-weight500">All Projects</div>
              </div>
              <div className="table-heading-flex">
                <div className="bloc-tabs-with-white-bg bloc-tabs-with-lesswhite-bg">
                  {["All", "pending", "inprogress", "completed", "hold", "cancelled"].map(
                    (status) => (
                      <button
                        key={status}
                        className={
                          toggleState === (status === "All" ? "null" : status)
                            ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                            : "dashboardcomponent-tabs white-tab"
                        }
                        onClick={() =>
                          setToggleState(status === "All" ? "null" : status)
                        }
                      >
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </button>
                    )
                  )}
                </div>
              </div>
              {buffer ? <div className="spinner-small"></div> :
                <div className="table-box">
                  <table className="custom-table">
                    <thead className="table-heading">
                      <tr className="custom-table-head-trr">
                        <th className="align-center font-size-text font-weight600">Sr.</th>
                        <th className="align-center font-size-text font-weight600">Pr. Code</th>
                        <th className="align-left font-size-text font-weight600">Project Name</th>
                        <th className="align-center font-size-text font-weight600">Cost (in cr)</th>
                        <th className="align-center font-size-text font-weight600">Client Name</th>
                        <th className="align-center font-size-text font-weight600">Concern Person</th>
                        <th className="align-center font-size-text font-weight600">Due Date</th>
                        <th className="align-center font-size-text font-weight600">Tender ID</th>
                        {/* <th className="align-center font-size-text font-weight600">Link</th> */}
                        <th className="align-center font-size-text font-weight600">Remark</th>
                        <th className="align-left font-size-text font-weight600">Project Link</th>

                        {
                          toggleState === "pending" ? null :
                            <th
                              className="align-center font-size-text font-weight600"
                            >
                              Update Stages
                            </th>}
                        {/* <th className="align-center font-size-text font-weight600"
                      >View</th> */}
                        <th
                          className="align-center font-size-text font-weight600"
                          style={{ paddingRight: "10px" }}
                        >
                          Update Details
                        </th>

                      </tr>
                    </thead>

                    <tbody>
                      {filteredData
                        // .sort((a, b) =>
                        //   a.client_name.localeCompare(b.client_name, "en", {
                        //     ignorePunctuation: true,
                        //   })
                        // )
                        .sort((a, b) =>
                          a.prebid_prcode?.localeCompare(b.prebid_prcode, "en", {
                            ignorePunctuation: true,
                          })
                        )
                        .map((i, index) => (
                          <React.Fragment key={index}>
                            <tr className="tr-border-bottom">
                              <td colSpan="8"></td>
                            </tr>
                            <tr className="custom-table-head-tdd">
                              <td className="align-center font-size-text font-weight400">{index + 1}</td>
                              <td className="align-center font-size-text font-weight400">{i.prebid_prcode}</td>
                              <td className="align-center font-size-text font-weight400">{i.project_short_name}</td>

                              <td className="align-center font-size-text font-weight400">{formatCurrencyIndian(i.cost)}</td>
                              <td className="align-center font-size-text font-weight400">{i.client_name}</td>
                              <td className="align-center font-size-text font-weight400">
                                {i.concern_person_name === "null"
                                  ? "-"
                                  : i.concern_person_name}
                              </td>
                              <td className="align-center font-size-text font-weight400">
                                {i.due_date === null
                                  ? ""
                                  : formattedDateLong(i.due_date)}
                              </td>
                              <td className="align-center font-size-text font-weight400">{i.tender_id}</td>
                              {/* <td className="align-center font-size-text font-weight400">
                              <a
                                href={i.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-link-45deg"
                                  viewBox="0 0 16 16"
                                  color="#010c15"
                                >
                                  <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                                  <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                                </svg>
                              </a>
                            </td> */}
                              <td className="align-center font-size-text font-weight400">
                                {i.remark === "null" ? "-" : i.remark}
                              </td>
                              <td className="align-center font-size-text font-weight400">
                                {i.link ?
                                  <>
                                    <a href={i.link} target="blank" className="document-download-button  width-5vw  width-5vw">
                                      Link
                                    </a>
                                  </>  
                                  :
                                  <>
                                  -
                                  </>
                              }
                              </td>
                              {toggleState === "pending" ?
                                null
                                :
                                (< td
                                  className="align-center font-size-text font-weight400"
                                >
                                  {i.status === "pending" ?
                                    "Update Details first"
                                    :

                                    <Updatestages id={i.pr_code} />
                                  }
                                </td>)
                              }
                              {/* <td className="align-center font-size-text font-weight400">
                              <Link to={`/ed/ViewProjectDetails/${i.pr_code}`}>
                                <button
                                  title="something"
                                  className="models-button model-add "
                                  onClick={handleShow}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                  >
                                    <circle
                                      cx="15"
                                      cy="15"
                                      r="15"
                                      fill="#F6F7F9"
                                    ></circle>
                                    <path
                                      d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                      fill="#2576BC"
                                    ></path>
                                  </svg>
                                </button>
                              </Link>
                            </td> */}
                              <td
                                className="align-center font-size-text font-weight400"

                              >
                                <div>
                                  <UpdateProject
                                    id={i.pr_code}
                                    modalData={i}
                                    allEmployeeData={allEmployeeData}
                                  />
                                </div>
                              </td>

                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              }
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default AllProjectDetails;
